import React, { useRef, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';

// redux
import { useDispatch } from "react-redux";
import CreatableSelect from 'react-select/creatable';

// partents
import { FormModal } from '@ui-partents/Modal';
import { components } from 'react-select';

// actions
import { editDocumentMedicalInfo } from "@actions";
import { shareReference } from "@actions";

const ModalEditReference = ({ title, isOpen, toggleModal, currentItem, loadDataReferenceBook, createOption }) => {
    const formikRef = useRef(null);
    const dispatch = useDispatch();
    
    // state
    const [listValue, setListValue] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [initialValues, setInitialValues] = useState({
        title: '',
        author_list: '',
        journal_citation: ''
    });
    const [loading, setLoading] = useState(false);

    // Validation schema
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
    });

    // Handle Click Save Button
    const handleSaveButtonClick = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const componentsCommon = {
        DropdownIndicator: () => null,
        MultiValueContainer: (props) => {
          return (
            <div className="custom-tag">
              <components.MultiValueContainer {...props} />
            </div>
          );
        }
    };

    const handleBlurAction = () => {
        if (inputValue) {
            setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        }
      }

    const handleAddEmail = (value) => {
        setListValue(value)
    }

    useEffect(() => {
        if (currentItem) {
            setInitialValues({
                title: currentItem.title || '',
                author_list: currentItem.author_list || '',
                journal_citation: currentItem.journal_citation || '',
            });
            const test = currentItem?.tags && currentItem.tags?.map((tag) => {
                return {
                    value: tag,
                    label: tag,
                }
            })
            setListValue(test)
        }
    }, [currentItem]);

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
            setInputValue('');
            event.preventDefault();
            break;
          default:
            break;
        }
    };

    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={title}
            onAction={() => handleSaveButtonClick()}
            actionText={"Save"}
            maxWidth="900px"
            iconConfig={{ iconName: "edit-modal", color: "#0089C2" }}
            isLoading={loading}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                innerRef={formikRef}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    setLoading(true);
                    const params = {
                        "action_type": "edit_document_info",
                        "document_id": currentItem.document_id,
                        "title": values.title,
                        "author_list": values.author_list,
                        "journal_citation": values.journal_citation
                    };
                    dispatch(editDocumentMedicalInfo(params))
                        .then(async(res) => {
                            setLoading(false);
                            if (res?.success) {
                                const listDataTags = listValue?.map((data) => data?.value)
                                if (currentItem?.tags?.length > 0) {
                                    await dispatch(shareReference({
                                        "Action": "Untag",
                                        "ReferenceId": currentItem?.document_id,
                                        "Tags": currentItem?.tags
                                    }))
                                }
                                if (listDataTags?.length > 0) {
                                    await dispatch(shareReference({
                                        "Action": "Tag",
                                        "ReferenceId": currentItem?.document_id,
                                        "Tags": listDataTags
                                    }))
                                }
                                loadDataReferenceBook()
                                toggleModal(); // Close the modal on success
                            } else {
                                console.error('Error occurred:', res.error);
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            console.error('Error occurred:', error);
                        });

                    setSubmitting(false);
                }}
            >
                {({ values, errors, handleChange, handleBlur, touched }) => (
                    <Form>
                        <FormGroup>
                            <Label className="labeForm" for="title">Title <span className="text-danger ml-1">*</span></Label>
                            <Input
                                type="text"
                                name="title"
                                id="title"
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={!!errors.title && touched.title}
                                placeholder='Enter document title'
                            />
                            <FormFeedback>{errors.title}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label className="labeForm" for="author_list">Author(s)</Label>
                            <Input
                                type="text"
                                name="author_list"
                                id="author_list"
                                value={values.author_list}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Enter author names'
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="labeForm" for="journal_citation">Journal (if applicable)</Label>
                            <Input
                                type="text"
                                name="journal_citation"
                                id="journal_citation"
                                value={values.journal_citation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder='Enter journal information'
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="labeForm" for="journal_citation">Tag(s)</Label>
                            <div className="email-share__form-input">
                                <CreatableSelect
                                    components={componentsCommon}
                                    inputValue={inputValue}
                                    isClearable
                                    isMulti
                                    menuIsOpen={false}
                                    onChange={(newValue) => handleAddEmail(newValue)}
                                    onInputChange={(newValue) => setInputValue(newValue)}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleBlurAction}
                                    placeholder="Type a tag, then press enter to add another"
                                    value={listValue}
                                    classNamePrefix={`select-tag`}
                                />
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </FormModal>
    );
};

export default ModalEditReference;