import React, { useState } from 'react'
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'
// import Copy from '@images/teachV2/copy.svg'
import ReactTooltip from 'react-tooltip'
import Feedback from '@images/teachV2/feedback.svg'
import GenerateImage from '@images/teachV2/generate-img.svg'
import McqWaiting from '@images/teachV2/waiting-mcq.gif'
import Regenerate from '@images/teachV2/regenerate.svg'
import {apiCaller} from "@utils";
import { useDispatch } from 'react-redux'
import { teachV2Constants } from "@constants";
import stick from '@images/teachV2/stick-btn.svg'
import GenrateFail from '@images/teachV2/gen-failed.gif'
// import Select from 'react-select';

export default function GenerateItemImage(props) {
  const { dataCaseScript, dataRef } = props
  const dispatch = useDispatch();

  const [showData, setShowData] = useState(true)
  const [showError, setShowError] = useState("")
  const [loadingGenerate, setLoadingGenerate] = useState(false)

  const handleShowData = () => {
    setShowData(!showData)
  }

  const handleGenerateImage = (e) => {
    e.preventDefault()
    setLoadingGenerate(true)
    
    const params = {
      action_type: "generate_case_image",
      id: dataCaseScript?.id,
    };
    apiCaller(`/api/medicalcase/functional/`, "POST", params).then((res) => {
      const resData = res?.data;
      if (res?.status === 200) {
        dispatch({ type: teachV2Constants.LOAD_CASE_SCRIPT_SUCCESS, payload: resData });
        setLoadingGenerate(false);
        setShowError("")
      } else {
        console.log("error");
        setShowError(res?.data?.error || "Oops! Something went wrong, it look like there are no questions generated at the moment.")
        setLoadingGenerate(false);
      }
    });
  }

  // const handleCopy = async (e) => {
  //   e.preventDefault()
  //   try {
  //     const img = new Image();
  //     img.crossOrigin = 'Anonymous'; // Thiết lập để hỗ trợ CORS
  //     img.src = dataCaseScript?.image_url;

  //     img.onload = async () => {
  //       const canvas = document.createElement('canvas');
  //       canvas.width = img.width;
  //       canvas.height = img.height;

  //       const ctx = canvas.getContext('2d');
  //       ctx.drawImage(img, 0, 0);

  //       canvas.toBlob(async (blob) => {
  //         const item = new ClipboardItem({ 'image/png': blob });
  //         await navigator.clipboard.write([item]);
  //         alert('Hình ảnh đã được sao chép vào clipboard!');
  //       }, 'image/png');
  //     };

  //     img.onerror = () => {
  //       console.log("Không thể sao chép ảnh");
  //     };
  //   } catch (error) {
  //     console.error('Có lỗi xảy ra: ', error);
  //   }
  // }

  // const handleFeedback = (title, dataRef) => {
  //   toggleModal("modalGiveFeedback")
  //   const text = title ? title.concat(" \n", dataRef?.current?.innerText) :  "Learning Points:".concat(" \n", dataRef?.current?.innerText)
  //   setDataFeedback(text)
  // }

  // const options = [
  //   { value: 'model1', label: 'Model 1' },
  //   { value: 'model2', label: 'Model 2' },
  //   { value: 'model3', label: 'Model 3' },
  //   { value: 'model4', label: 'Model 4' },
  // ]

  return (
    <div className='generateItem'>
      <div className="generateItem__header w-100">
        <div className="generateItem__header-title">
          <div className="icon-show" onClick={handleShowData}>
            {showData ? <Icon src={Up}/> : <Icon src={Down} stroke="#1D2939"/>}
          </div>
          <p className={`${showData ? "text-show" : ""}`}>Interaction Persona Profile Image</p>
          </div>        
        <div className="generateItem__header-action">
          {!dataCaseScript?.image_url ? (
            <>
              {/* <div className="generateItem__header-action--select">
                <Select
                  className={`react-select-common w-100`}
                  options={options}
                  classNamePrefix="react-select-custom"
                  placeholder="Select interaction persona"
                  menuPlacement="top"
                />
              </div> */}
              <div
                className={`${loadingGenerate ? "btn-loading" : ""} d-flex align-items-center btn-image`}
                onClick={(e) => handleGenerateImage(e)}
              >
                <Icon src={loadingGenerate ? stick : GenerateImage} width={20} height={20} />
                <span>Generate</span>
              </div>
            </>
          ) : (
            loadingGenerate ? (
              <>
              </>
            ) : (
              <>
                <div 
                  data-tip
                  data-for={`regenerate-image`}
                  className="action-copy p-1" 
                  onClick={(e) => handleGenerateImage(e)}
                >
                  <Icon src={Regenerate}/>
                </div>
                <ReactTooltip id={`regenerate-image`} place='top' effect='solid' className="case-tooltip">
                  <span>Re-generate This Section</span>
                </ReactTooltip>
                {/* <div 
                  data-tip
                  data-for={`copy-image`}
                  className="action-copy p-1" 
                  onClick={(e) => handleCopy(e)}
                >
                  <Icon src={Copy}/>
                </div>
                <ReactTooltip id={`copy-copy-image`} place='top' effect='solid' className="case-tooltip">
                  <span>Copy this Section</span>
                </ReactTooltip> */}
                <div 
                  data-tip
                  data-for={`feedback-image`}
                  className="action-copy p-1" 
                  // onClick={() => handleFeedback(titleGenerate, dataRef)}
                  onClick={(e) => e.preventDefault()}
                >
                  <Icon src={Feedback}/>
                </div>
                <ReactTooltip id={`feedback-image`} place='top' effect='solid' className="case-tooltip">
                  <span>Report Issues With This Section</span>
                </ReactTooltip>
              </>
            )
          )}
        </div>
      </div>
      {showData && (
        <div ref={dataRef} className='w-100 mt-2 text-center'>
          {loadingGenerate ? (
            <div className="generateItem__loading">
              <img className='img-wait' src={McqWaiting} alt="mcq" />
              <div className="generateItem__loading-content">
                <h1>Generating The Patient Image</h1>
                <p>Just a moment... your patient image is <br/> on the way!</p>
              </div>
            </div>
          ) : (
            !dataCaseScript?.image_url ? (
              showError ? (
                <div className="generateItem__failed">
                  <div className="d-flex justify-content-center">
                    <img src={GenrateFail} alt="Oops" />
                  </div>
                  <p className="text-center mt-3">{showError}</p>
                </div>
              ) : (
                <span className="generateItem__explain">Click ‘Generate’ to create an image for the selected persona. <br />This will be used as the profile picture in the converted interactive module.</span> 
              )
            ) : (
              <div className="generateItem__image">
                <img src={dataCaseScript?.image_url} alt="default" />
              </div>
            )
          )}
        </div>
      )}
    </div>
  )
}
