import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Icon } from '@components/common'
import FileCase from '@images/teachV2/file-case.svg'
import { useSelector, useDispatch } from "react-redux";
import { MessageEmtyData } from "@ui-partents/Message";
import { getReferenceMedia } from "@actions";
import { wizardConstants } from "@constants";
import HeaderCommon from './HeaderCommon';

// Import the pagination configuration
import {paginationOptions} from "@utils";

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function SelectListCommonImage(props) {
  const { setValue, dataForm, watch } = props;
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const { data: documents } = useSelector((state) => state.getReferenceMedia) || {};
  const [selectedBooks, setSelectedBooks] = useState(watch(dataForm?.field) || []);
  const [filteredData, setFilteredData] = useState(documents?.Combined || []); // dữ liệu gốc

  useEffect(() => {
    dispatch(getReferenceMedia())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (documents?.Combined) {
      setFilteredData(documents.Combined); // Cập nhật dữ liệu đã lọc khi có thay đổi dữ liệu gốc
    }
  }, [documents]);

  const handleActionBack = () => {
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_MEDIA_SUCCESS, payload: null });
    setValue(dataForm?.field, null)
  }

  const columns = [
    {
      dataField: "title",
      text: "",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      editable: false,
      style: {
        width: "96%",
      },
      formatter: (value, row) => {
        return (
          <div className={`d-flex justify-content-between item-book`}>
            <div className='d-flex'>
              <Icon src={FileCase} />
              <p className="ml-2">{value}</p>
            </div>
          </div>
        );
      },
    },
  ]

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    // hideSelectColumn: true,
    selectColumnPosition: 'right',
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedBooks([row]);
        setValue(dataForm?.field, [row])
      } else {
        setSelectedBooks(selectedBooks.filter((book) => book.id !== row.id));
        setValue(dataForm?.field, selectedBooks.filter((book) => book.id !== row.id))
      }
    },
    selected: selectedBooks.map(book => book.id)
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="id"
          data={filteredData || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
                <SearchBar 
                  {...toolkitprops.searchProps} 
                  onSearch={(searchText) => {
                    toolkitprops.searchProps.onSearch(searchText);
                    setFilteredData(documents?.Combined.filter(doc => 
                      doc.title.toLowerCase().includes(searchText.toLowerCase())
                    ));
                  }} 
                />
              </div>
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive list-common"
                data={filteredData || []}
                bordered={false}
                columns={columns}
                noDataIndication={() => (
                  <MessageEmtyData
                    title="No Results Found"
                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                  />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                selectRow={selectRow}
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="pagination-custom-case d-flex justify-content-end ml-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  // Calculate the total size based on documents
  const totalSize = filteredData.length || 0;

  // Retrieve pagination settings dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon {...props} />
        <div className="form-area__select">
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        </div>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon {...props} dataPass={!selectedBooks?.length}  handleBack={handleActionBack}/>
      </div>     
    </WrapCommon>
  )
}
