import React, { useState } from 'react'
import { Icon } from '@components/common'
import Feedback from '@images/teachV2/feedback.svg'
import Save from '@images/teachV2/save-flag.svg'
import Saved from '@images/teachV2/saved-flag.svg'
import ReactTooltip from 'react-tooltip';
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';
import ResultMedia from './ResultMedia'
import Copy from '@images/teachV2/copy.svg'
import check from '@images/teachV2/check-done.svg'

const ResultItem = (props) => {
  const { index, dataContentFile, question, setShowModal, setDataFeedback, activeTab } = props
  const [isSaved, setIsSaved] = useState(false)
  const [mediaId, setMediaId] = useState(question?.media[0]?.id)
  const [showCheckDone, setShowCheckDone] = useState(false)
  
  const copy = (question, listAnswers) => {
    setShowCheckDone(true)
    const answers = listAnswers.map((ans) => `${ans.id}. ${ans.content}`)
    const text = question.concat(" \n", answers.join(" \n"))
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setShowCheckDone(false)
    }, 2000)
  }

  const handleFeedback = (question, listAnswers) => {
    setShowModal(true)
    const answers = listAnswers.map((ans) => `${ans.id}. ${ans.content}`)
    const text = question.concat(" \n", answers.join(" \n"))
    setDataFeedback(text)
  }

  const saveQuestionBank = (id) => {
    let params
    if (dataContentFile[id]?.media[0]?.id) {
      params = {
        "action_type": "save_to_question_bank",
        "contents": dataContentFile[id],
        "media_item_id": mediaId
      }
    } else {
      params = {
        "action_type": "save_to_question_bank",
        "contents": dataContentFile[id],
      }
    }
    apiCaller(`/api/medicalcase/functional/`, "POST", params)
      .then(response => {
        if (response.status === 200) {
          toast.success('Successfully saved to Question Bank', {
            position: "bottom-right",
            autoClose: 2000,
          });
          setIsSaved(true)
        }
      })
      .catch(error => {
        console.error("Error in API call", error);
        toast.warning('Save question bank was failed!', {
          position: "bottom-right",
          autoClose: 2000,
        });
      });
  }

  return (
    <div className={`question-item ${activeTab === question.id ? 'active' : ''}`}>
      <div className="question-item__quest">
        <div className='question-item__quest-header'>
          <p>Version #{index}</p>
          <div className='action-question d-flex align-items-center'>
            <div
              data-tip
              data-for={`save-${index}`}
              className={`action-copy p-1 ${isSaved && "disable-save"}`}
              onClick={!isSaved ? () => saveQuestionBank(index) : () => console.log("Saved")}
            >
              <Icon src={isSaved ? Saved : Save}  stroke="#475467"/>
            </div>
            <ReactTooltip id={`save-${index}`} place='top' effect='solid' className="mcq-tooltip">
              <span>{isSaved ? "Question already saved" : "Save To Question Bank Under My Inventory"}</span>
            </ReactTooltip>
            <div 
              data-tip
              data-for={`copy-${index}`}
              className="action-copy p-1" 
              onClick={(e) => copy(question?.question, question.multiple_choices)}
            >
              <Icon src={showCheckDone ? check : Copy}/>
            </div>
            <ReactTooltip id={`copy-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Copy This Question</span>
            </ReactTooltip>
            <div 
              data-tip
              data-for={`feedback-${index}`}
              className="action-copy p-1" 
              onClick={() => handleFeedback(question?.question, question.multiple_choices)}
            >
              <Icon src={Feedback}/>
            </div>
            <ReactTooltip id={`feedback-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Report Issues With This Question</span>
            </ReactTooltip>
          </div>
        </div>
        <p className='quest-text'>{question?.question}</p>
      </div>
      <div className="question-item__answers">
        {question.multiple_choices?.map((choice, i) => (
          <p key={i} className={`${choice.id === question?.best_answer?.id ? "correct-answer" : ""} answer`}>
            {choice.id}. {choice.content}
          </p>
        ))}
      </div>
      <div className="question-item__explanation w-100">
        {question?.media?.length > 0 && (
          <>
            <p>Media </p>
            <ResultMedia question={question} setMediaId={setMediaId} mediaId={mediaId} {...props}/>
          </>
        )}
      </div>
      <div className="question-item__explanation">
        <p>Explanation </p>
        <span>{question?.best_answer?.explanation}</span>
      </div>
    </div>
  )
}

export default ResultItem
