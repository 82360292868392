import React from 'react';
import { useParams } from 'react-router-dom';
import { FormPage, TablesPage, MessagePage, Generate, LayoutMainPage, ButtonsPage, AvatarPage } from '@pages/PatternsUI';

function DesignPatternsPage() {
    const { slug } = useParams();

    const renderPage = {
        form: <FormPage />,
        tables: <TablesPage />,
        message: <MessagePage />,
        generate: <Generate />,
        layout: <LayoutMainPage />,
        button: <ButtonsPage />,
        avatar: <AvatarPage />,
    };

    //SideBar
    return (
        <div className="wrapper -designPatternsPage">
            <div className="content-wrapper">
                <div className="container">
                    <div className="container-fluid">
                        {renderPage[slug] || (
                            <div className="content-header">
                                <h1>Dashboard</h1>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesignPatternsPage;