export const listChatGpt = [
  {
    value: "gpt-4o",
    label: "gpt-4o",
  },
  {
    value: "gpt-4",
    label: "gpt-4",
  },
]

export const listTemplate = [
  {
    value: 1,
    label: "Advanced",
  },
  {
    value: 2,
    label: "Standard",
  },
]

export const listPopular = [
  "NBME Question",
  "gpt-4o",
  "gpt-4",
  "gpt-4-turbo",
  "Advanced",
]

export const listGenerate = [
  {
    value: "diagnosis",
    label: "Diagnosis & Differential Diagnoses",
    type: "Diagnosis",
  },
  {
    value: "management",
    label: "Management",
    type: "Management",
  },
  {
    value: "mcq",
    label: "Question List",
    type: "Questions",
  },
]

export const listFileAccept = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/csv",
  "application/vnd.ms-excel",
  "application/msword",
  "application/word",
  "text/plain",
]

export const interactionLanguages = [
  {
    value: "English",
    label: "English",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese",
  }
];

export const interactionMethods = [
  {
    value: "Text",
    label: "Text Only",
  },
  {
    value: "Voice",
    label: "Voice Only",
  }
];

export const listTypeUploadMedia = [
  {
    value: "Audio",
    label: "Audio",
  },
  {
    value: "Image",
    label: "Image",
  },
  {
    value: "Video",
    label: "Video",
  },
]

export const listAspectRatio = [
  {
    value: "16:9",
    label: "16:9",
  },
  {
    value: "4:3",
    label: "4:3",
  },
  {
    value: "16:10",
    label: "16:10",
  },
  {
    value: "21:9",
    label: "21:9",
  },
  {
    value: "32:9",
    label: "32:9",
  },
]

export const arrayTypeMedia = ["URL", "Audio", "Image", "Video"]
