import React, { useState } from 'react';

//Icon
import { Iconsax } from "@components-common";

//redux
import { useSelector } from "react-redux";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

//components
import { SendIcon } from "../FreeText";

function InputFreeText(props) {
  const { handleQuestionTypePatient, disabled, caseData, valueOptions, textareaRef } = props && props;
  const TimePointId = caseData?.data?.Case?.Timeline?.CurrentTimePointId;

  // Interface Template
  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const VirtualPreceptor = InterfaceTemplateDetails?.VirtualPatient;
  const patient_reply = VirtualPreceptor?.patient_reply?.Text || "Enter your statement ...";

  // Check Lock Elements
  const isChatPending = useSelector((state) => state.isChatPending || {});
  const isLock = isChatPending?.isLock;

  //state
  const [value, setValue] = useState('');

  //send Api
  const handleSubmit = () => {
    const params = {
      Action: "Submit",
      Message: value || valueOptions,
      TimePointId: TimePointId
    }
    if (value || valueOptions) {
      handleQuestionTypePatient(params);
      setValue('');
    }
  }

  //handlechange
  const handleChange = (e) => {
    setValue(e.target.value);
  }

  //Handle Key Press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Ngăn hành vi mặc định của Enter khi không nhấn Shift
      event.preventDefault();
      handleSubmit();
    }
  }

  // Use screen size context
  const { isMobile } = useScreenSize();
  
  return (
    <div className="chatFooter">
      <div className="position-relative w-100">
        <textarea
          ref={textareaRef}
          name="answer"
          placeholder={patient_reply}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
          disabled={disabled || isLock}
          autoComplete="off"
          rows="3" // Số dòng hiển thị mặc định
          className={`form-control ${isMobile ? "textarea-format-mobile" : ""}`} // Class CSS để áp dụng kiểu dáng
          style={{ resize: "none", width: "100%" }} // Ngăn resize và làm cho chiều rộng 100%
        />
        {isMobile && <SendIcon onClick={handleSubmit} />}
      </div>
      {!isMobile &&
        <button
          type="button"
          className="btn-send ml-2"
          disabled={disabled || isLock}
          onClick={handleSubmit}
        >
          <span className="mr-2">Send</span> <Iconsax iconName="send" fill="#fff" />
        </button>
      }
    </div>
  );
};

export default InputFreeText;