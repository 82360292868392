import React, { useState, useEffect } from 'react';

//components
import { ButtonClosePanel } from "@components/learner/StandardScreen";

// Components
import { ModalMessageVoice } from "@components/common/VoiceMessage";

const ChatPanel = (props) => {
    // props
    const { stateType, children, isCurrentTask, isOpen } = props && props;
    const divId = stateType?.question ? "is-view-question" : "is-view-patient";

    // State to manage modal visibility
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen])
    
    return (
        <React.Fragment>
            <ButtonClosePanel
                {...props}
                isCurrentTask={stateType?.question ? "question" : isCurrentTask}
            />
            <div id={divId} className="frameChat">
                {children}
                <ModalMessageVoice
                    {...props}
                    heading={`Notice About Voice Feature`}
                    description={`This case supports Voice-based chat, but the Voice feature isn’t available on iOS devices. You can still interact with the patient using Text. To explore the Voice feature, please use a desktop, laptop, or Android device. We apologize for the inconvenience and are actively working on a solution. All other features remain fully accessible - enjoy your experience!`}
                    isOpen={isModalOpen}
                />
            </div>
        </React.Fragment>
    );
}

export default ChatPanel;