import React, { useEffect, useState } from 'react';

//react-hook-form
import { useForm } from "react-hook-form";

//reactstrap
import {
    Button,
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

//toast
import { toast } from 'react-toastify';

//redux
import { useDispatch } from "react-redux";

//common
import { SwitchItem } from "@ui-partents/SwitchButton";

// constants
import { storyboardConstants } from "@constants";

// components
import { AutomaticLauchFeedback } from "../Chat";

function Settings(props) {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { timepointData, caseData, timePointCurrent } = props && props;

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();

    const [isLoading, setLoading] = useState(false);
    const [stateSwitch, setSwitch] = useState({
        ChatToolbar: timepointData?.data?.ChatSettings?.ChatToolbar,
        InstantChatFeedbackOption: timepointData?.data?.ChatSettings?.InstantChatFeedbackOption,
        EndCaseFeedback: timepointData?.data?.ChatSettings?.EndCaseFeedback,
        AutomatedFeedbackTrigger: timepointData?.data?.ChatSettings?.AutomatedFeedbackTrigger,
        AutomatedFeedbackTriggerCount: timepointData?.data?.ChatSettings?.AutomatedFeedbackTriggerCount
    });
    const [automatedFeedback, setAutomatedFeedback] = useState({
        AutomatedFeedbackTrigger: timepointData?.data?.ChatSettings?.AutomatedFeedbackTrigger,
        AutomatedFeedbackTriggerCount: timepointData?.data?.ChatSettings?.AutomatedFeedbackTriggerCount
    });
    const [stateDomain, setDomain] = useState(caseData?.Domains || []);
    const [stateOptions] = useState({
        InteractWith: [
            { name: 'Patient', value: 'Patient' },
            { name: 'Mother', value: 'Mother' },
            { name: 'Father', value: 'Father' },
            { name: 'Son', value: 'Son' },
            { name: 'Daughter', value: 'Daughter' }
        ],
        InteractLanguage: [
            { name: 'English', value: 'English' },
            { name: 'Vietnamese', value: 'Vietnamese' },
            { name: 'Korean', value: 'Korean' }
        ],
        GenderInteractingCharacter: [
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
        ],
        AIModel: [
            { name: 'gpt-3.5-turbo', value: 'gpt-3.5-turbo' },
            { name: 'gpt-4', value: 'gpt-4' }
        ]
    });

    // Load Domain
    useEffect(() => {
        setDomain(caseData?.Domains || []);
    }, [caseData])

    // Thêm object mới vào danh sách
    useEffect(() => {
        // Thêm object mới vào danh sách
        const newObject = {
            Id: "",
            Name: "Select a value",
            Description: "",
            Color: "#1a1a1a"
        };
        setDomain(prevDomains => [...prevDomains, newObject]);
    }, []); // Chạy useEffect chỉ một lần khi component được mount

    //load Data
    useEffect(() => {
        setValue("InteractWith", timepointData?.data?.ChatSettings?.InteractWith || '', { shouldDirty: false });
        setValue("InteractLanguage", timepointData?.data?.ChatSettings?.InteractLanguage || '', { shouldDirty: false });
        setValue("AgeInteractingCharacter", timepointData?.data?.ChatSettings?.AgeInteractingCharacter || '', { shouldDirty: false });
        setValue("GenderInteractingCharacter", timepointData?.data?.ChatSettings?.GenderInteractingCharacter || 0, { shouldDirty: false });
        setValue("AIModel", timepointData?.data?.ChatSettings?.AIModel || '', { shouldDirty: false });
    }, [setValue, timepointData]);

    // Render Options
    const renderOptions = (type) => {
        const valueItem = timepointData?.data?.ChatSettings;
        return (
            stateOptions && stateOptions[type]?.map((item, index) => <option key={index} selected={valueItem[type] === item?.value} defaultValue={item?.value}>{item?.name}</option>)
        )
    }

    //Handle Submit
    const onSubmit = (data) => {
        const params = {
            "Action": "Update",
            "Target": "ChatSettings",
            "InteractWith": data?.InteractWith,
            "InteractLanguage": data?.InteractLanguage,
            "AgeInteractingCharacter": data?.AgeInteractingCharacter,
            "GenderInteractingCharacter": data?.GenderInteractingCharacter,
            "AIModel": data?.AIModel,
            "DomainId": parseInt(data?.DomainId) || "",
            "InstantChatFeedbackOption": stateSwitch?.InstantChatFeedbackOption,
            "EndCaseFeedback": stateSwitch?.EndCaseFeedback,
            "ChatToolbar": stateSwitch?.ChatToolbar,
            "AutomatedFeedbackTrigger": automatedFeedback?.AutomatedFeedbackTrigger,
            "AutomatedFeedbackTriggerCount": automatedFeedback?.AutomatedFeedbackTriggerCount > 0 ? Number(automatedFeedback?.AutomatedFeedbackTriggerCount) : 0,
            "TimePointId": timePointCurrent?.Id
        }
        setLoading(true);
        dispatch({
            type: storyboardConstants.CHAT_SETTINGS_REQUEST,
            isLoading: true,
        });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
                setLoading(false);
                toast.success(`Data update successfully !`, {
                    position: "top-right"
                });
            } else {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: 'error' });
                setLoading(false);
            }
        });
    }

    // Handle Change Switch
    const handleChangeSwitch = (e) => {
        const { name, checked } = e.target;
        setSwitch((prevState) => ({
            ...prevState,
            [name]: checked
        }));
    }

    // Handle Change Lauch Feedback
    const handleChangeLauchFeedback = (e) => {
        const { name, value, checked } = e.target;
        if(name === 'AutomatedFeedbackTrigger') {
            setAutomatedFeedback((prevState) => ({
                ...prevState,
                [name]: checked
            }));
        } else {
            setAutomatedFeedback((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    }

    return (
        <div className="interactive-chat-settings">
            <Row className="align-items-center">
                <Col md={6}>
                    <FormGroup>
                        <Label for="InteractWith">
                            Interacting with:
                        </Label>
                        <Input
                            {...register("InteractWith")}
                            id="InteractWith"
                            name="InteractWith"
                            type="select"
                            onChange={(e) => {
                                setValue("InteractWith", e.target.value, { shouldValidate: true });
                            }}
                            defaultValue={watch("InteractWith")}
                        >
                            {renderOptions('InteractWith')}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="InteractLanguage">
                            Interacting Language:
                        </Label>
                        <Input
                            {...register("InteractLanguage")}
                            id="InteractLanguage"
                            name="InteractLanguage"
                            type="select"
                            onChange={(e) => {
                                setValue("InteractLanguage", e.target.value, { shouldValidate: true });
                            }}
                            defaultValue={watch("InteractLanguage")}
                        >
                            {renderOptions('InteractLanguage')}
                        </Input>
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Label for="AgeInteractingCharacter">Age of interacting character:</Label>
                        <Input
                            {...register("AgeInteractingCharacter", {
                                required: "This field is required.",
                                pattern: {
                                    value: /^(0?[1-9]|[1-9][0-9])$/,
                                    message: "Please enter a number from 1 to 99",
                                },
                            })}
                            invalid={errors.AgeInteractingCharacter?.type === "required"}
                            type="number"
                            name="AgeInteractingCharacter"
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => {
                                setValue("AgeInteractingCharacter", e.target.value, { shouldValidate: true });
                            }}
                            defaultValue={watch("AgeInteractingCharacter")}
                            inputMode="numeric"
                            min={0}
                            max={99}
                        />
                        {errors.AgeInteractingCharacter?.message && (
                            <FormFeedback className="d-block">
                                {errors.AgeInteractingCharacter?.message}
                            </FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label for="GenderInteractingCharacter">
                            Gender of interacting character:
                        </Label>
                        <Input
                            {...register("GenderInteractingCharacter")}
                            id="GenderInteractingCharacter"
                            name="GenderInteractingCharacter"
                            type="select"
                            onChange={(e) => {
                                setValue("GenderInteractingCharacter", e.target.value, { shouldValidate: true });
                            }}
                            defaultValue={watch("GenderInteractingCharacter")}
                        >
                            {renderOptions('GenderInteractingCharacter')}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="AIModel">
                            Gender of interacting character:
                        </Label>
                        <Input
                            {...register("AIModel")}
                            id="AIModel"
                            name="AIModel"
                            type="select"
                            onChange={(e) => {
                                setValue("AIModel", e.target.value, { shouldValidate: true });
                            }}
                            defaultValue={watch("AIModel")}
                        >
                            {renderOptions('AIModel')}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="Domain">
                            Domain
                        </Label>
                        <Input
                            id="DomainId"
                            name="DomainId"
                            type="select"
                            onChange={(e) => {
                                setValue("DomainId", e.target.value);
                            }}
                        >
                            <React.Fragment>
                                {stateDomain?.map((item, index) => <option key={index} selected={item?.Id === timepointData?.data?.ChatSettings?.DomainId} value={item?.Id}>{item?.Name}</option>)}
                            </React.Fragment>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="border-top"></div>
                </Col>
                <Col md={12}>
                    <SwitchItem
                        {...props}
                        label={`Chat Toolbar`}
                        name={`ChatToolbar`}
                        handleChangeSwitch={handleChangeSwitch}
                        value={stateSwitch?.ChatToolbar}
                    />
                </Col>
                {stateSwitch?.ChatToolbar && (
                    <React.Fragment>
                    <Col md={4}>
                        <SwitchItem
                            {...props}
                            label={`Instant Feedback`}
                            name={`InstantChatFeedbackOption`}
                            handleChangeSwitch={handleChangeSwitch}
                            value={stateSwitch?.InstantChatFeedbackOption}
                        />
                    </Col>
                    <Col md={8}>
                        <AutomaticLauchFeedback 
                            {...props}
                            handleChange={handleChangeLauchFeedback}
                            automatedFeedback={automatedFeedback}
                        />
                    </Col>
                </React.Fragment>
                )}
                <Col md={12}>
                    <SwitchItem
                        {...props}
                        label={`End-Of-Case Feedback`}
                        name={`EndCaseFeedback`}
                        handleChangeSwitch={handleChangeSwitch}
                        value={stateSwitch?.EndCaseFeedback}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Button
                        color="danger"
                        className="btn btn-brand --bg-primary d-flex align-items-center"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                </Col>
            </Row>
        </div >
    );
};

export default Settings;