import React, { useState } from 'react';
import { FlagModal } from "../Preceptor";
import { ModalDiscussThisQuestion } from "@components/common/Feedback";
import { BtnReviewErm } from '../FreeText';

//Email components
import { BtnViewEmail } from "../../EmailTemplate";

function InstructorMessage(props) {
    const { value, loadingIndex, index, PreceptorAvatar, preceptorNameText } = props || {};

    const [modal, setModal] = useState({
        DiscussThisQuestion: false
    });

    const toggle = (type, item, data) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const ShowEmail = value?.FullQuestionResponse?.ShowEmail;
    const ShowEMR = value?.FullQuestionResponse?.ShowEMR;
    return (
        <React.Fragment>
            <div className={`messengerCt__wrapper isShow`} data-index={loadingIndex === index}>
                <div className="messengerCt__message messengerCt__message--in">
                    <div className="messengerCt__message-pic">
                        <img
                            src={PreceptorAvatar}
                            alt={preceptorNameText}
                        />
                    </div>
                    <div className="messengerCt__message-body position-relative">
                        <div className={`messengerCt__message-content --arrow-left`}>
                            <div className="text-right" style={{ position: 'absolute', right: '10px', top: '10px' }}>
                                <FlagModal
                                    {...props}
                                    toggle={toggle}
                                />
                            </div>
                            <div className="messengerCt__message-username mt-4">
                                <div className='role-chat'>{`${preceptorNameText || "Instructor"}:`}</div>
                            </div>
                            <div className="messengerCt__message-text">
                                {/* Hiển thị câu hỏi */}
                                {value?.Content && <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: value?.Content }} />}
                                {/* Hiển thị Explanation khi là free_text */}
                                {value?.Explanation && (
                                    <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: value?.Explanation }} />
                                )}
                                <div className="d-flex algin-items-center justify-content-end">
                                    {ShowEmail && <BtnViewEmail {...props} values={value} />}
                                    {ShowEMR && <BtnReviewErm {...props} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDiscussThisQuestion
                isOpen={modal?.DiscussThisQuestion}
                toggle={() => toggle("DiscussThisQuestion")}
                handleClose={() => toggle("DiscussThisQuestion")}
                type="chat"
                currentChatData={value}
                questionId={value?.Id}
            />
        </React.Fragment>
    );
}

export default InstructorMessage;