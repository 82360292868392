import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
} from "reactstrap";

//components
import { DragDropZoneFile } from "@ui-partents/Form/FormUpload";
import { ViewDataImport } from "../../instructor";

//redux
import { useDispatch } from "react-redux";

//api
import {apiCaller} from "@utils";
import { instructorConstants } from "@constants";

//lodash
import { isEmpty } from "lodash";

function ModalImportExel(props) {
  const dispatch = useDispatch();
  const { isOpen, toggle } = props && props;
  const childRef = useRef();

  //state
  const [isValid, setValid] = useState(false);
  const [viewData, setviewData] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]); //files
  const [listData, setData] = useState([]);

  //handleUploadImage
  const handleUploadImage = (data) => {
    if (isEmpty(data)) {
      setValid(true);
    } else {
      //Set File FormData
      data.map((file) => {
        setFileData(file);
        setValid(false);
        return true;
      });
    }
  };

  //submit
  const onSubmit = () => {
    const formData = new FormData();
    formData.append("Mode", "List");
    formData.append("ImportFile", fileData);

    //loading
    setLoading(true);

    //Call Api
    apiCaller(`/api/account/import/`, "POST", formData).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: instructorConstants.IMPORT_EXEL_ACCOUNT_SUCCESS,
          payload: data
        });
        setData(data);
        setTimeout(() => {
          setLoading(false);
          setviewData(true);
        }, 3000);
      } else {
        dispatch({
          type: instructorConstants.IMPORT_EXEL_ACCOUNT_FAILURE,
          error: "error",
        });
      }
    });
  };

  const handleBack = () => {
    setFileData([]);
    setviewData(false);
  }

  const linkDowload = `https://docs.google.com/uc?id=1yZME3E825gc27-Omq9EU42mlqTuawK-C&export=download`;
  return (
    <Modal style={{ maxWidth: "900px" }} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {viewData ? "Account" : "Upload File"}
      </ModalHeader>
      <ModalBody>
        {!viewData ? (
          <React.Fragment>
            <p className="mb-3 font-weight-500">Dowload and use this <a href={linkDowload} title={`Excel template`} download>Excel template</a>, then upload the file below</p>
            <DragDropZoneFile
              onChange={handleUploadImage}
              acceptFile=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              value={""}
            />
            {isValid && (
              <FormFeedback className="d-block mt-2">
                The file must be a file of type: csv, xlsx, xls
              </FormFeedback>
            )}
          </React.Fragment>
        ) : (
          <ViewDataImport toggle={toggle} data={listData} ref={childRef} />
        )}
      </ModalBody>
      <ModalFooter>
        <a
          className="btn btn-success mr-auto"
          href={linkDowload} title={`Dowload Template`}
        >
          Dowload Template
        </a>
        {!viewData ? (
          <Button
            disabled={isLoading || isEmpty(fileData)}
            color="primary"
            className="btn btn-brand --bg-primary d-flex align-items-center"
            onClick={onSubmit}
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Import
          </Button>
        ) : (
          <Button
            color="primary"
            className="btn btn-brand --bg-primary ml-auto d-block"
            onClick={() => childRef.current.sendApi()}
          >
            Create New Accounts
          </Button>
        )}
        <Button color="secondary" onClick={viewData ? handleBack : toggle} disabled={isLoading}>
          {viewData ? "Back" : "Cancel"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalImportExel;
