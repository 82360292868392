import React, { useState, useContext, useEffect } from 'react';

// Chat components
import {
    ChatClick,
    ChatFreeText,
    ChatVoiceBox,
    ChatQuestion,
    ChatPanel
} from "../Chat";
import { ModalEmailTemplate } from "@components/learner/StandardScreen/EmailTemplate";

//redux
import { useSelector, useDispatch } from "react-redux";

//context
import { DeviceContext } from '@context/DeviceContext';

//react-router-dom
import { useParams } from "react-router-dom";

//call Action
import { atcAddRecordToChanel } from "@actions";

function ChatInterface(props) {

    //variables
    let { id } = useParams();
    const dispatch = useDispatch();

    //context
    const { device, deviceType } = useContext(DeviceContext);  // Get device and device type from context

    //props
    const { children, ChatMode: propChatMode, data, handleOverideEffectMenu, switchChat, stateType } = props;

    //State to store the modified ChatMode
    const [ChatMode, setChatMode] = useState(propChatMode);

    // State to manage modal open/close
    const [isOpenMessage, setIsOpenMessage] = useState(false); // Renamed to isOpenMessage

    //Timepoint
    const CaseProgressReducer = useSelector((state) => (state?.caseTaskCurrent?.data) || {});
    let TimePointId = CaseProgressReducer?.CaseProgress?.CurrentTimePointProgress?.TimePointId;

    //Check Chat View - Mobile
    const isOpen = useSelector((state) => state.typeChatforChatFeedback.isOpen);

    //Chat - Add Record
    const apiCallerAddChatRecord = async (data) => {
        const params = {
            "Action": "AddData",
            "CaseId": parseInt(id),
            "TimePointId": parseInt(TimePointId),
            "Data": data
        };

        return new Promise(async (resolve, reject) => {
            try {
                await dispatch(atcAddRecordToChanel(params)); // Wait for dispatch to complete
                resolve(); // Resolve the promise if dispatch is successful
            } catch (error) {
                reject(error); // Reject the promise if there's an error
            }
        });
    };

    // State to store message from voice to submit free text
    const [messageFromVoiceToText, setFromVoiceToText] = useState('');

    // State to control Email Template visibility
    const [isEmailTemplate, setEmailTemplate] = useState({
        data: {},
        isToggle: false
    });

    // Handle Toggle Email Template
    const handleToggleEmailTemplate = (values) => {
        setEmailTemplate(prevState => ({
            ...prevState,
            isToggle: !prevState.isToggle,
            data: values || null
        }));
    };

    // Flag to ensure we only switch once
    const [hasSwitched, setHasSwitched] = useState(false);

    // Check Chat Mode - Fix iOS not supported
    useEffect(() => {
        const isIOSDevice = device === 'iOS' && (deviceType === 'iPhone' || deviceType === 'iPad');
        const shouldSwitchToFreeText = isIOSDevice && isOpen === 'chat';

        // Only switch once and always override to FreeText if conditions are met
        if (propChatMode === 'voice' && shouldSwitchToFreeText && !hasSwitched) {
            setChatMode('freetext');  // Override to freetext
            setIsOpenMessage(true);   // Open message modal only once
            setHasSwitched(true);     // Ensure we don't switch again
        }
    }, [device, deviceType, propChatMode, isOpen, hasSwitched]);

    return (
        <ChatPanel
            {...props}
            isOpen={isOpenMessage}
            handleClose={() => setIsOpenMessage(false)}
        >
            {/* Hiển thị view Instructor và Patient bên trong component này */}
            {children}

            {/* Display Email Template if the state is true */}
            {isEmailTemplate?.isToggle &&
                <ModalEmailTemplate
                    {...props}
                    isOpen={isEmailTemplate?.isToggle}
                    handleToggleEmailTemplate={handleToggleEmailTemplate}
                    isEmailTemplate={isEmailTemplate}
                />
            }

            {stateType?.chat && (
                <>
                    {ChatMode === "click" && (
                        <ChatClick
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                        />
                    )}
                    {ChatMode === "freetext" && (
                        <ChatFreeText
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                            apiCallerAddChatRecord={apiCallerAddChatRecord}
                            handleToggleEmailTemplate={handleToggleEmailTemplate}
                        />
                    )}
                    {ChatMode === "voice" && (
                        <ChatVoiceBox
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                        />
                    )}
                    {ChatMode === "switch_voice_text" && switchChat === "freetext" && (
                        <ChatFreeText
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                            messageFromVoiceToText={messageFromVoiceToText}
                            apiCallerAddChatRecord={apiCallerAddChatRecord}
                            handleToggleEmailTemplate={handleToggleEmailTemplate}
                        />
                    )}
                    {ChatMode === "switch_voice_text" && switchChat === "voice" && (
                        <ChatVoiceBox
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                            handleQuestionTypePatient={(value) => {
                                setFromVoiceToText(value);
                            }}
                        />
                    )}
                </>
            )}
            {stateType?.question && (
                <ChatQuestion
                    {...props}
                    ChatMode={ChatMode}
                    apiCallerAddChatRecord={apiCallerAddChatRecord}
                    handleToggleEmailTemplate={handleToggleEmailTemplate}
                />
            )}
        </ChatPanel>
    );
}

export default ChatInterface;