import React, { useEffect } from 'react';
import { BtnIconDeleteV2, BtnIconEditV2 } from "@ui-partents/Button";
import { useSelector, useDispatch } from "react-redux";
import { getAllEngages } from "@actions";
import { useHistory } from 'react-router-dom'
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { Icon } from '@components/common'
import { MessageEmtyData } from "@ui-partents/Message";
import File from '@images/teachV2/file-upload.svg';
import { LoaderText } from "@utils";
import PlayIcon from '@images/common/play-run-case.svg'

//moment
import moment from "moment";
import { Button } from 'reactstrap';
import WrapModalEditEngage from './WrapModalEditEngage';

// Import the pagination configuration
import { paginationOptions } from "@utils";

const EngageLibrary = () => {
  const { SearchBar } = Search;
  let history = useHistory();
  const dispatch = useDispatch();
  const listCase = useSelector((state) => state?.getAllEngages || []);
  
  const handleChatEngage = (id) => {
    history.push({
      pathname: `/engage/${id}`,
    })
  };

  useEffect(() => {
    dispatch(getAllEngages({
      action_type: "get_all_topic"
    }));
  }, [dispatch])

  const columns = [
    {
      dataField: "topic_name",
      text: "Module Name",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "gpt_model",
      text: "LLM Model",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "reference_title",
      text: "Reference",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {cell && <Icon src={File}/>}
            <p className='ml-1'>{cell}</p>
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <div className='format-time text-nowrap'>{moment(cell).format('DD/MM/YYYY hh:mm A')}</div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "--text-primary font-weight-500 text-center",
      headerStyle: { width: "72px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-start">
            <div className="btn-run" onClick={() => handleChatEngage(row?.topic_id)}>
              <Icon src={PlayIcon} color="#0089C3"/>
              Run Module
            </div>
            <WrapModalEditEngage
              title={`Edit Module`}
              dataDetail={row}
            >
              {({onOpen}) => (
                <BtnIconEditV2
                  handleEditButtonClick={onOpen}
                  colorIcon={`#16B364`}
                />
              )}
            </WrapModalEditEngage>
            <WrapModalDeleteCommon
              title={`Are you sure you'd like to delete this engage?`}
              sub={`This action is not reversible`}
              handleRemove={() => handleRemove(row?.topic_id)}
              idRemove={row?.id}
            >
              {({onOpen}) => (
                <BtnIconDeleteV2 handleDelete={onOpen} />
              )}
            </WrapModalDeleteCommon>
          </div>
        );
      },
    }
  ];

  const handleRemove = (idDelete) => {
    const params = {
      action_type: "delete_topic",
      topic_id: idDelete,
    };
    dispatch(getAllEngages(params));
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="topic_id"
        data={listCase?.data || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-library__filter d-flex align-items-center justify-content-between">
              <p>Total Engagement Modules: {listCase?.data?.length}</p>
              <div className="boxSearch">
                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                  paginationProps.page = 1
                  toolkitprops.searchProps.onSearch(e)
                }}/>
                <Button
                  color="primary"
                  className='ml-2 p-2 w-100'
                  onClick={() => history.push("/teach/engage")}
                >
                  Create Module
                </Button>
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={(listCase?.data && listCase?.data) || []}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );
  
  return (
    <div className="table-library position-relative">
      {listCase?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
      ) : (
        listCase?.data?.length === 0 ? (
          <>
            <div className="d-flex align-items-center justify-content-end">
              <Button
                color="primary"
                className='ml-2 p-2'
                onClick={() => history.push("/teach/engage")}
              >
                Create Module
              </Button>
            </div>
            <MessageEmtyData
              title="Your Library is Empty"
              text="It looks like you haven't generated any case scenarios yet. Start creating a new one to see it here."
            />
          </>
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationOptions(listCase?.data?.length || 0))}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default EngageLibrary
