// FormPage.js
import { 
    MyForm, 
    InputDifferentHeight, 
    HeadingForm, 
    ProgressComponent, 
    DropdownInput, 
    RadioInput, 
    CheckboxInput, 
    ToastComponent,
    TabsComponent 
} from "@components/patterns/elements/Form";
import React from 'react';
import { Row, Col } from 'reactstrap';

const FormPage = () => {
    return (
        <section className="content-component mb-5">
            <Row className='mb-4'>
                <Col md={12}>
                    <div className="content-header">
                        <h1>General Form</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <MyForm />
                </Col>
                <Col md={6}>
                    <InputDifferentHeight />
                    <DropdownInput />
                    <RadioInput />
                    <CheckboxInput />
                    <HeadingForm />
                    <ProgressComponent />
                    <ToastComponent />
                    <TabsComponent />
                </Col>
            </Row>
        </section>
    );
};

export default FormPage;