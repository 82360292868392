export const teachV2Constants = {
    GET_LIST_CASE_SCRIPT_REQUEST: 'GET_LIST_CASE_SCRIPT_REQUEST',
    GET_LIST_CASE_SCRIPT_SUCCESS: 'GET_LIST_CASE_SCRIPT_SUCCESS',
    GET_LIST_CASE_SCRIPT_FAILURE: 'GET_LIST_CASE_SCRIPT_FAILURE',

    LOAD_CASE_SCRIPT_REQUEST: 'LOAD_CASE_SCRIPT_REQUEST',
    LOAD_CASE_SCRIPT_SUCCESS: 'LOAD_CASE_SCRIPT_SUCCESS',
    LOAD_CASE_SCRIPT_FAILURE: 'LOAD_CASE_SCRIPT_FAILURE',

    SAVE_TITLE_SCRIPT_REQUEST: 'SAVE_TITLE_SCRIPT_REQUEST',
    SAVE_TITLE_SCRIPT_SUCCESS: 'SAVE_TITLE_SCRIPT_SUCCESS',
    SAVE_TITLE_SCRIPT_FAILURE: 'SAVE_TITLE_SCRIPT_FAILURE',

    SAVE_CASE_SCRIPT_REQUEST: 'SAVE_CASE_SCRIPT_REQUEST',
    SAVE_CASE_SCRIPT_SUCCESS: 'SAVE_CASE_SCRIPT_SUCCESS',
    SAVE_CASE_SCRIPT_FAILURE: 'SAVE_CASE_SCRIPT_FAILURE',

    REMOVE_CASE_SCRIPT_REQUEST: 'REMOVE_CASE_SCRIPT_REQUEST',
    REMOVE_CASE_SCRIPT_SUCCESS: 'REMOVE_CASE_SCRIPT_SUCCESS',
    REMOVE_CASE_SCRIPT_FAILURE: 'REMOVE_CASE_SCRIPT_FAILURE',

    GET_TEMPLATE_SCRIPT_REQUEST: 'GET_TEMPLATE_SCRIPT_REQUEST',
    GET_TEMPLATE_SCRIPT_SUCCESS: 'GET_TEMPLATE_SCRIPT_SUCCESS',
    GET_TEMPLATE_SCRIPT_FAILURE: 'GET_TEMPLATE_SCRIPT_FAILURE',

    GET_QUESTION_BANK_REQUEST: 'GET_QUESTION_BANK_REQUEST',
    GET_QUESTION_BANK_SUCCESS: 'GET_QUESTION_BANK_SUCCESS',
    GET_QUESTION_BANK_FAILURE: 'GET_QUESTION_BANK_FAILURE',

    GET_QUESTION_BANK_ORG_REQUEST: 'GET_QUESTION_BANK_ORG_REQUEST',
    GET_QUESTION_BANK_ORG_SUCCESS: 'GET_QUESTION_BANK_ORG_SUCCESS',
    GET_QUESTION_BANK_ORG_FAILURE: 'GET_QUESTION_BANK_ORG_FAILURE',

    SHARE_MEDIA_ORG_REQUEST: 'SHARE_MEDIA_ORG_REQUEST',
    SHARE_MEDIA_ORG_SUCCESS: 'SHARE_MEDIA_ORG_SUCCESS',
    SHARE_MEDIA_ORG_FAILURE: 'SHARE_MEDIA_ORG_FAILURE',

    REMOVE_QUESTION_BANK_REQUEST: 'REMOVE_QUESTION_BANK_REQUEST',
    REMOVE_QUESTION_BANK_SUCCESS: 'REMOVE_QUESTION_BANK_SUCCESS',
    REMOVE_QUESTION_BANK_FAILURE: 'REMOVE_QUESTION_BANK_FAILURE',

    GET_LIST_LEARNER_REQUEST: 'GET_LIST_LEARNER_REQUEST',
    GET_LIST_LEARNER_SUCCESS: 'GET_LIST_LEARNER_SUCCESS',
    GET_LIST_LEARNER_FAILURE: 'GET_LIST_LEARNER_FAILURE',

    REMOVE_LEARNER_REQUEST: 'REMOVE_LEARNER_REQUEST',
    REMOVE_LEARNER_SUCCESS: 'REMOVE_LEARNER_SUCCESS',
    REMOVE_LEARNER_FAILURE: 'REMOVE_LEARNER_FAILURE',

    ENROLL_EMAIL_REQUEST: 'ENROLL_EMAIL_REQUEST',
    ENROLL_EMAIL_SUCCESS: 'ENROLL_EMAIL_SUCCESS',
    ENROLL_EMAIL_FAILURE: 'ENROLL_EMAIL_FAILURE',

    GET_RAG_DOCUMENT_BY_ACCOUNT_REQUEST: 'GET_RAG_DOCUMENT_BY_ACCOUNT_REQUEST',
    GET_RAG_DOCUMENT_BY_ACCOUNT_SUCCESS: 'GET_RAG_DOCUMENT_BY_ACCOUNT_SUCCESS',
    GET_RAG_DOCUMENT_BY_ACCOUNT_FAILURE: 'GET_RAG_DOCUMENT_BY_ACCOUNT_FAILURE',

    UPLOAD_RAG_DOCUMENT_REQUEST: 'UPLOAD_RAG_DOCUMENT_REQUEST',
    UPLOAD_RAG_DOCUMENT_SUCCESS: 'UPLOAD_RAG_DOCUMENT_SUCCESS',
    UPLOAD_RAG_DOCUMENT_FAILURE: 'UPLOAD_RAG_DOCUMENT_FAILURE',

    MCQ_USAGE_REQUEST: 'MCQ_USAGE_REQUEST',
    MCQ_USAGE_SUCCESS: 'MCQ_USAGE_SUCCESS',
    MCQ_USAGE_FAILURE: 'MCQ_USAGE_FAILURE',

    DETAILED_MCQ_USAGE_REQUEST: 'DETAILED_MCQ_USAGE_REQUEST',
    DETAILED_MCQ_USAGE_SUCCESS: 'DETAILED_MCQ_USAGE_SUCCESS',
    DETAILED_MCQ_USAGE_FAILURE: 'DETAILED_MCQ_USAGE_FAILURE',

    EDIT_DOCUMENT_MEDICAL_INFO_REQUEST: 'EDIT_DOCUMENT_MEDICAL_INFO_REQUEST',
    EDIT_DOCUMENT_MEDICAL_INFO_SUCCESS: 'EDIT_DOCUMENT_MEDICAL_INFO_SUCCESS',
    EDIT_DOCUMENT_MEDICAL_INFO_FAILURE: 'EDIT_DOCUMENT_MEDICAL_INFO_FAILURE',

    DELETE_RAG_DOCUMENT_REQUEST: 'DELETE_RAG_DOCUMENT_REQUEST',
    DELETE_RAG_DOCUMENT_SUCCESS: 'DELETE_RAG_DOCUMENT_SUCCESS',
    DELETE_RAG_DOCUMENT_FAILURE: 'DELETE_RAG_DOCUMENT_FAILURE',

    GET_SLIDE_TEMPLATES_REQUEST: 'GET_SLIDE_TEMPLATES_REQUEST',
    GET_SLIDE_TEMPLATES_SUCCESS: 'GET_SLIDE_TEMPLATES_SUCCESS',
    GET_SLIDE_TEMPLATES_FAILURE: 'GET_SLIDE_TEMPLATES_FAILURE',

    GET_SLIDE_DESKS_REQUEST: 'GET_SLIDE_DESKS_REQUEST',
    GET_SLIDE_DESKS_SUCCESS: 'GET_SLIDE_DESKS_SUCCESS',
    GET_SLIDE_DESKS_FAILURE: 'GET_SLIDE_DESKS_FAILURE',

    REMOVE_SLIDE_DESK_REQUEST: 'REMOVE_SLIDE_DESK_REQUEST',
    REMOVE_SLIDE_DESK_SUCCESS: 'REMOVE_SLIDE_DESK_SUCCESS',
    REMOVE_SLIDE_DESK_FAILURE: 'REMOVE_SLIDE_DESK_FAILURE',

    LOAD_SLIDE_DESK_REQUEST: 'LOAD_SLIDE_DESK_REQUEST',
    LOAD_SLIDE_DESK_SUCCESS: 'LOAD_SLIDE_DESK_SUCCESS',
    LOAD_SLIDE_DESK_FAILURE: 'LOAD_SLIDE_DESK_FAILURE',

    CREATE_ENGAGE_REQUEST: 'CREATE_ENGAGE_REQUEST',
    CREATE_ENGAGE_SUCCESS: 'CREATE_ENGAGE_SUCCESS',
    CREATE_ENGAGE_FAILURE: 'CREATE_ENGAGE_FAILURE',

    EDIT_ENGAGE_REQUEST: 'EDIT_ENGAGE_REQUEST',
    EDIT_ENGAGE_SUCCESS: 'EDIT_ENGAGE_SUCCESS',
    EDIT_ENGAGE_FAILURE: 'EDIT_ENGAGE_FAILURE',

    DELETE_ENGAGE_REQUEST: 'DELETE_ENGAGE_REQUEST',
    DELETE_ENGAGE_SUCCESS: 'DELETE_ENGAGE_SUCCESS',
    DELETE_ENGAGE_FAILURE: 'DELETE_ENGAGE_FAILURE',

    GET_ALL_ENGAGE_REQUEST: 'GET_ALL_ENGAGE_REQUEST',
    GET_ALL_ENGAGE_SUCCESS: 'GET_ALL_ENGAGE_SUCCESS',
    GET_ALL_ENGAGE_FAILURE: 'GET_ALL_ENGAGE_FAILURE',

    GET_REFERENCE_BOOK_REQUEST: 'GET_REFERENCE_BOOK_REQUEST',
    GET_REFERENCE_BOOK_SUCCESS: 'GET_REFERENCE_BOOK_SUCCESS',
    GET_REFERENCE_BOOK_FAILURE: 'GET_REFERENCE_BOOK_FAILURE',

    FILTER_REFERENCE_BOOK_REQUEST: 'FILTER_REFERENCE_BOOK_REQUEST',
    FILTER_REFERENCE_BOOK_SUCCESS: 'FILTER_REFERENCE_BOOK_SUCCESS',
    FILTER_REFERENCE_BOOK_FAILURE: 'FILTER_REFERENCE_BOOK_FAILURE',

    SHARE_REFERENCE_BOOK_REQUEST: 'SHARE_REFERENCE_BOOK_REQUEST',
    SHARE_REFERENCE_BOOK_SUCCESS: 'SHARE_REFERENCE_BOOK_SUCCESS',
    SHARE_REFERENCE_BOOK_FAILURE: 'SHARE_REFERENCE_BOOK_FAILURE',

    UPLOAD_FILE_ANALYSIS_REQUEST: 'UPLOAD_FILE_ANALYSIS_REQUEST',
    UPLOAD_FILE_ANALYSIS_SUCCESS: 'UPLOAD_FILE_ANALYSIS_SUCCESS',
    UPLOAD_FILE_ANALYSIS_FAILURE: 'UPLOAD_FILE_ANALYSIS_FAILURE',

    CREATE_NEW_ANALYSIS_REQUEST: 'CREATE_NEW_ANALYSIS_REQUEST',
    CREATE_NEW_ANALYSIS_SUCCESS: 'CREATE_NEW_ANALYSIS_SUCCESS',
    CREATE_NEW_ANALYSIS_FAILURE: 'CREATE_NEW_ANALYSIS_FAILURE',

    GET_LIST_ANALYSIS_AVAILABLE_REQUEST: 'GET_LIST_ANALYSIS_AVAILABLE_REQUEST',
    GET_LIST_ANALYSIS_AVAILABLE_SUCCESS: 'GET_LIST_ANALYSIS_AVAILABLE_SUCCESS',
    GET_LIST_ANALYSIS_AVAILABLE_FAILURE: 'GET_LIST_ANALYSIS_AVAILABLE_FAILURE',

    GET_ANALYSES_ANSWER_BATCH_REQUEST: 'GET_ANALYSES_ANSWER_BATCH_REQUEST',
    GET_ANALYSES_ANSWER_BATCH_SUCCESS: 'GET_ANALYSES_ANSWER_BATCH_SUCCESS',
    GET_ANALYSES_ANSWER_BATCH_FAILURE: 'GET_ANALYSES_ANSWER_BATCH_FAILURE',

    GET_ANALYSES_ANSWER_BATCH_STATISTICS_REQUEST: 'GET_ANALYSES_ANSWER_BATCH_STATISTICS_REQUEST',
    GET_ANALYSES_ANSWER_BATCH_STATISTICS_SUCCESS: 'GET_ANALYSES_ANSWER_BATCH_STATISTICS_SUCCESS',
    GET_ANALYSES_ANSWER_BATCH_STATISTICS_FAILURE: 'GET_ANALYSES_ANSWER_BATCH_STATISTICS_FAILURE',

    GET_REFERENCE_MEDIA_REQUEST: 'GET_REFERENCE_MEDIA_REQUEST',
    GET_REFERENCE_MEDIA_SUCCESS: 'GET_REFERENCE_MEDIA_SUCCESS',
    GET_REFERENCE_MEDIA_FAILURE: 'GET_REFERENCE_MEDIA_FAILURE',

    FILTER_REFERENCE_MEDIA_REQUEST: 'FILTER_REFERENCE_MEDIA_REQUEST',
    FILTER_REFERENCE_MEDIA_SUCCESS: 'FILTER_REFERENCE_MEDIA_SUCCESS',
    FILTER_REFERENCE_MEDIA_FAILURE: 'FILTER_REFERENCE_MEDIA_FAILURE',

    CREATE_REFERENCE_MEDIA_REQUEST: 'CREATE_REFERENCE_MEDIA_REQUEST',
    CREATE_REFERENCE_MEDIA_SUCCESS: 'CREATE_REFERENCE_MEDIA_SUCCESS',
    CREATE_REFERENCE_MEDIA_FAILURE: 'CREATE_REFERENCE_MEDIA_FAILURE',

    DELETE_REFERNECE_MEDIA_REQUEST: 'DELETE_REFERNECE_MEDIA_REQUEST',
    DELETE_REFERNECE_MEDIA_SUCCESS: 'DELETE_REFERNECE_MEDIA_SUCCESS',
    DELETE_REFERNECE_MEDIA_FAILURE: 'DELETE_REFERNECE_MEDIA_FAILURE',

    SHARE_REFERENCE_MEDIA_REQUEST: 'SHARE_REFERENCE_MEDIA_REQUEST',
    SHARE_REFERENCE_MEDIA_SUCCESS: 'SHARE_REFERENCE_MEDIA_SUCCESS',
    SHARE_REFERENCE_MEDIA_FAILURE: 'SHARE_REFERENCE_MEDIA_FAILURE',

    EDIT_REFERENCE_MEDIA_REQUEST: 'EDIT_REFERENCE_MEDIA_REQUEST',
    EDIT_REFERENCE_MEDIA_SUCCESS: 'EDIT_REFERENCE_MEDIA_SUCCESS',
    EDIT_REFERENCE_MEDIA_FAILURE: 'EDIT_REFERENCE_MEDIA_FAILURE',

    REGENERATE_CASE_SCRIPT_REQUEST: 'REGENERATE_CASE_SCRIPT_REQUEST',
    REGENERATE_CASE_SCRIPT_SUCCESS: 'REGENERATE_CASE_SCRIPT_SUCCESS',
    REGENERATE_CASE_SCRIPT_FAILURE: 'REGENERATE_CASE_SCRIPT_FAILURE',
};