import React from 'react';

// Redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { toggleMenuMobile } from "@actions";

const ButtonMenuMobile = (props) => {
  const dispatch = useDispatch();
  const isOpenMenuMobile = useSelector((state) => state.isOpenMenuMobile.isOpen);

  // Hàm toggle menu
  const toggleMenu = () => {
    dispatch(toggleMenuMobile(!isOpenMenuMobile));
  };

  const lineStyle = {
    width: '32px',
    height: '3px',
    backgroundColor: '#000',
    margin: '4px 0',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'all 0.3s ease', // Thêm transition để tạo hiệu ứng mềm mại
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onClick={toggleMenu} // thêm sự kiện onClick để toggle menu
    >
      <div style={lineStyle}></div>
      <div style={lineStyle}></div>
      <div style={lineStyle}></div>
    </div>
  );
};

export default ButtonMenuMobile;