import React, { useEffect, useState } from "react";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

//utils
import { LoaderText } from "@utils";

//lodash
import _ from "lodash";

//reactstrap
import { Col, Row } from "reactstrap";

//redux
import { useSelector } from "react-redux";

//common
import { DataEmty } from "@components/common";

//reactstrap
import { Badge } from "reactstrap";

//components
import { FromCreate, FilterAuthCode } from "../Cohorts";

// Import the pagination configuration
import { paginationOptions } from "@utils";

function AuthTab(props) {

  //props
  const { message } = props && props;

  //state
  const [stateListAuth, setListAuth] = useState([]);
  const [onFilter, setOnFilter] = useState(false);
  const [arrFilter, setFilter] = useState({
    Status: '',
    Assigned: ''
  });

  const columns = [
    {
      dataField: "Id",
      text: "ID",
      headerClasses: "--bg-primary font-weight-400",
      headerStyle: (colum, colIndex) => {
        return { width: "50px" };
      },
      formatter: (value, row, colIndex) => {
        return (<span>{colIndex + 1}</span>);
      }
    },
    {
      dataField: "Code",
      text: "Authentication Code",
      headerClasses: "--bg-primary font-weight-400",
    },
    {
      dataField: "Assigned",
      text: "Assigned",
      headerClasses: "--bg-primary align-middle text-center font-weight-400",
      classes: "align-middle text-center",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      formatter: (value, row) => {
        return (
          <React.Fragment>
            {value && (
              <span className="icon-checkmark-switcher text-success"></span>
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "Status",
      text: "Status",
      headerClasses: "--bg-primary align-middle text-center font-weight-400",
      classes: "align-middle text-center",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      formatter: (value, row) => {
        const checkStatus = (status) => {
          let statusBlock = "";
          switch (status) {
            case "active":
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="success"
                >
                  {value}
                </Badge>
              );
              break;
            default:
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="danger"
                >
                  Deactivate
                </Badge>
              );
          }
          return statusBlock;
        };
        return <div>{checkStatus(value)}</div>;
      },
    },
  ];

  const listAuthCode = useSelector((state) => state.authCodeList || []);
  const storeListAuthCode = listAuthCode && listAuthCode.data;

  useEffect(() => {
    setListAuth((storeListAuthCode && storeListAuthCode) || []);
  }, [storeListAuthCode]);

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="Code"
        data={(stateListAuth && stateListAuth) || []}
        columns={columns}
      >
        {(toolkitprops) => (
          <BootstrapTable
            condensed
            striped
            wrapperClasses="table-responsive"
            bordered={false}
            noDataIndication={() => <DataEmty content={message} />}
            {...toolkitprops.baseProps}
            {...paginationTableProps}
          />
        )}
      </ToolkitProvider>
      {/* /Table */}
      <div className="pagination-custom-case d-flex justify-content-center my-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </div>
  );

  const handleFilter = (e, key) => {
    const { value } = e.target;
    setFilter((prevState) => ({ ...prevState, [key]: value }));
    setOnFilter(true);
  }

  //filter
  useEffect(() => {
    const filterByDropdown = () => {
      const listDataClone = _.cloneDeep(storeListAuthCode);
      const valueStatus = arrFilter.Status;
      const valueAssigned = arrFilter.Assigned;

      if (valueStatus) {
        const filterData = _.filter(listDataClone, { 'Status': valueStatus });
        setListAuth(filterData);
      } else if (valueAssigned) {
        const filterData = _.filter(listDataClone, { 'Assigned': valueAssigned === 'true' });
        setListAuth(filterData);
      } else if (valueAssigned && valueStatus) {
        const filterData = _.filter(listDataClone, { 'Assigned': valueAssigned === 'true', 'Status': valueStatus });
        setListAuth(filterData);
      } else {
        setListAuth(listDataClone);
      }
      setOnFilter(false);
    }

    if (onFilter) {
      filterByDropdown();
    }
  }, [storeListAuthCode, onFilter, arrFilter.Assigned, arrFilter.Status]);

  const totalSize = stateListAuth?.length || 0; // Dynamically calculate the total size

  // Use the paginationOptions function to get the configuration
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div
      className="authenticationCodeCt position-relative"
      style={{ height: !listAuthCode.isLoading ? "300px" : "auto" }}
    >
      {listAuthCode.isLoading && <LoaderText className="h-100" />}
      {!listAuthCode.isLoading && (
        <Row>
          <FilterAuthCode hanldeFilter={handleFilter} />
          <Col md={6}>
            <FromCreate {...props} />
          </Col>
          <Col md={12}>
            <PaginationProvider pagination={paginationFactory(paginationConfig)}>
              {contentTable}
            </PaginationProvider>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default AuthTab;
