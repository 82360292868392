/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { TabComponentBorder } from "@ui-partents/Tabs";

//reactstrap
import { Button } from "reactstrap";

// Components
import { HeaderFeature } from ".";
import {
    QuestionText,
    AnswersTab,
    OverallExplanation,
    FlowControl,
    QuestionDomains,
} from "./CreateQuestion";

//toast
import { toast } from "react-toastify";

//constants
import { teachConstants } from "@constants";

//redux
import { useDispatch, useSelector } from "react-redux";

//lodash
import _ from "lodash";

//react-router-dom
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

const CreateUpdateQuestion = (props) => {
    const { ...otherProps } = props;
    const { timepointData, handleClickBack, itemQuestion } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    // State
    const [isLoading, setLoading] = useState(false);
    const [questionData, setQuestionData] = useState({
        TimePointId: timepointData?.Id,
        NewTimePointId: null,
        Task: "history",
        QuestionContent: "",
        QuestionExplanation: "",
        QuestionType: "multiple_choice",
        Domains: [],
        FollowUpExplanationRequired: false,
        GradingSchema: "dialog",
        AI_Instructions: {
            Text: ''
        },
        Scoring_Instructions: {
            Text: ''
        },
        PromptTemplateIds: [],
        Answers: [],
        ImmediateFeedbackMode: true
    });

    // ----------FlowConditions
    // ----------Begin State FlowConditions
    const [isCondition, setIsCondition] = useState('');
    const [flowControlOfQuestion, setFlowControlOfQuestion] = useState({
        ConditionType: '',
        QuestionId: '',
        Status: '',
        QuestionAnswers: [],
    });
    const [flowControlOfTopic, setFlowControlOfTopic] = useState({
        "ChatTopicId": '',
        "TopicName": '',
        "Status": ''
    });
    const [flowControlOfOrder, setFlowControlOrder] = useState({
        "OrderId": '',
        "OrderName": '',
        "Status": ''
    });
    const [flowControlOfAction, setFlowControlAction] = useState({
        "ActionId": '',
        "ActionName": '',
        "Status": ''
    });
    // ----------End State FlowConditions

    // Set Data With questionViewActive === "Edit_Question"
    useEffect(() => {
        if (questionViewActive === "Edit_Question" && itemQuestion) {
            setQuestionData({
                TimePointId: itemQuestion.TimePointId,
                NewTimePointId: itemQuestion.TimePointId,
                Task: itemQuestion.AskedAtTask,
                QuestionContent: itemQuestion.QuestionContent,
                QuestionExplanation: itemQuestion.QuestionExplanation,
                QuestionType: itemQuestion.QuestionType,
                Domains: itemQuestion.Domains,
                FollowUpExplanationRequired: itemQuestion.FollowUpExplanationRequired,
                GradingSchema: itemQuestion.GradingSchema,
                AI_Instructions: itemQuestion.AI_Instructions,
                Scoring_Instructions: itemQuestion.Scoring_Instructions,
                PromptTemplateIds: itemQuestion.PromptTemplates.map(pt => pt.Id),
                Answers: itemQuestion.Answers,
                ImmediateFeedbackMode: itemQuestion.ImmediateFeedbackMode
            });

            // Extract and map FlowConditions
            let flowConditions = itemQuestion?.FlowConditions || [];
            let currentFlowConditions = flowConditions?.length > 0 ? flowConditions[0] : {};
            if (flowConditions?.length > 0) {
                setIsCondition(currentFlowConditions?.ConditionType);
                setFlowControlOfQuestion({
                    QuestionId: currentFlowConditions?.QuestionId || '',
                    ConditionType: currentFlowConditions?.ConditionType,
                    Status: currentFlowConditions?.Status,
                    QuestionAnswers: currentFlowConditions?.QuestionAnswers || []
                });
            }
        }
    }, [questionViewActive, itemQuestion]);

    // Handle Change dropdown Chat Topic
    const handleUpdateFlowControlChatTopic = (data) => {
        let dataClone = { ...flowControlOfTopic };
        if ('Status' in data) {
            dataClone.Status = data.Status;
        } else {
            dataClone[`ChatTopicId`] = data?.value;
            dataClone[`TopicName`] = data?.label;
        }
        setFlowControlOfTopic(dataClone);
    };

    // Hanlde Change Dropdown Order Id
    const handleUpdateFlowControlOrderId = (data) => {
        let dataClone = { ...flowControlOfOrder };
        if ('Status' in data) {
            dataClone.Status = data.Status;
        } else {
            dataClone[`OrderId`] = data?.value;
            dataClone[`OrderName`] = data?.label;
        }
        setFlowControlOrder(dataClone);
    };

    // Handle Change For Actions
    const handleUpdateFlowControlActionId = (data) => {
        let dataClone = { ...flowControlOfAction };
        if ('Status' in data) {
            dataClone.Status = data.Status;
        } else {
            dataClone[`ActionId`] = data?.value;
            dataClone[`ActionName`] = data?.label;
        }
        setFlowControlAction(dataClone);
    };

    // Handle Change Answer By Condition
    const handleChangeAnswerFlowControl = (data) => {
        let dataClone = { ...flowControlOfQuestion }
        if ('Status' in data) {
            dataClone["Status"] = data.Status;
        } else {
            const resultAnswer = _.map(data, "value");
            dataClone[`ConditionType`] = "Question";
            dataClone[`QuestionAnswers`] = resultAnswer;
        }
        setFlowControlOfQuestion(dataClone);
    };

    // Handle Change Flow Control
    const handleUpdateFlowControl = (type, data) => {
        switch (type) {
            case "Question":
                handleChangeAnswerFlowControl(data);
                break;
            case "Chat":
                handleUpdateFlowControlChatTopic(data);
                break;
            case "Order":
                handleUpdateFlowControlOrderId(data);
                break;
            case "Action":
                handleUpdateFlowControlActionId(data);
                break;
            default:
                break;
        }
    };

    //Update Question Data
    const updateQuestionData = (newData) => {
        setQuestionData(prevState => ({
            ...prevState,
            ...newData
        }));
    };

    // Handle Change Question Type
    const handleChangeQuestionType = useCallback((type) => {
        updateQuestionData({ QuestionType: type.value });
    }, []);

    // Handle Change Prompt Template
    const handleChangePromptTemplate = useCallback((data) => {
        const listTemplateIds = data?.map((s) => s.value);
        updateQuestionData({ PromptTemplateIds: listTemplateIds });
    }, []);

    // Handle Change Explanation Checkbox
    const handleChangeExplanationCheckbox = useCallback((statusChecked) => {
        updateQuestionData({ FollowUpExplanationRequired: statusChecked });
    }, []);

    // Handle Grading Schema
    const handleChangeSchema = (type) => {
        updateQuestionData({ GradingSchema: type });
    };

    // Answer List - Handle Change
    const onDataAnswerChange = (data) => {
        const filteredData = data.filter(item => item.AnswerContent.trim() !== "");
        updateQuestionData({ Answers: filteredData });
    };

    // Handle Change Domains
    const handleChangeDomains = (NewDomains) => {
        updateQuestionData({ Domains: NewDomains });
    };

    // Handle Change Question Text
    const handleChangeQuestionContent = (value) => {
        updateQuestionData({ QuestionContent: value });
    }

    //Handle Change Overall Explanation
    const handleOverallExplanation = (value) => {
        updateQuestionData({ QuestionExplanation: value });
    }

    //Handle Change Status By Condition
    const handleChangeStatusByCondition = (data) => {
        handleUpdateFlowControl(isCondition, { Status: data });
    };

    // Handle Change Condition Type
    const handleChangeStateCondition = (type) => {
        setIsCondition(type);
        let dataClone = { ...flowControlOfQuestion }
        dataClone[`ConditionType`] = type
        setFlowControlOfQuestion(dataClone);
    }

    // Handle Change Question ID by Condition (Question)
    // Ở View Create thì không có QuestionId
    const handleChangeQuestionID = (idQuestionSelected) => {
        let dataClone = { ...flowControlOfQuestion }
        dataClone[`QuestionId`] = idQuestionSelected
        setFlowControlOfQuestion(dataClone);
    }

    // Handle Change Showing Condition Text
    // const handleChangeShowingConditionText = (value) => {
    //     updateQuestionData({ ShowingConditionText: value });
    // }

    // Handle Change Task
    const handleChangeAskedAtTask = (value) => {
        updateQuestionData({ Task: value });
    }

    // Handle Change Timepoint Id
    const handelSelectTimepointId = (value) => {
        updateQuestionData({ NewTimePointId: parseInt(value) });
    }

    // Handle Change AI_Instructions, Scoring_Instructions
    const handleChangeInstructionOfGradingSchema = (event) => {
        const { name, value } = event.target;
        setQuestionData((prevState) => ({
            ...prevState,
            [name]: { Text: value }
        }));
    }

    // Define tab content data
    const tabsContent = [
        {
            key: 1, label: "Question", content:
                <QuestionText
                    {...otherProps}
                    handleChangeQuestionContent={handleChangeQuestionContent}
                    questionData={questionData}
                />
        },
        {
            key: 2, label: "Answers", content:
                <AnswersTab
                    {...otherProps}
                    handleChangeQuestionType={handleChangeQuestionType}
                    handleChangePromptTemplate={handleChangePromptTemplate}
                    handleChangeExplanationCheckbox={handleChangeExplanationCheckbox}
                    handleChangeSchema={handleChangeSchema}
                    onDataAnswerChange={onDataAnswerChange}
                    questionData={questionData}
                    QuestionType={questionData?.QuestionType}
                    handleChangeInstructionOfGradingSchema={handleChangeInstructionOfGradingSchema}
                />
        },
        {
            key: 3, label: "Overall Explanation", content:
                <OverallExplanation
                    {...otherProps}
                    handleOverallExplanation={handleOverallExplanation}
                    questionData={questionData}
                />
        },
        {
            key: 4, label: "Flow Control", content:
                <FlowControl
                    {...otherProps}
                    isCondition={isCondition}
                    handleChangeStateCondition={handleChangeStateCondition}
                    handleChangeStatusByCondition={handleChangeStatusByCondition}
                    handleUpdateFlowControl={handleUpdateFlowControl}
                    handleChangeQuestionID={handleChangeQuestionID}
                    handleChangeAskedAtTask={handleChangeAskedAtTask}
                    handelSelectTimepointId={handelSelectTimepointId}
                    questionData={questionData}
                    flowControlOfQuestion={flowControlOfQuestion}
                // handleChangeShowingConditionText={handleChangeShowingConditionText}
                />
        },
        {
            key: 5, label: "Domains", content:
                <QuestionDomains
                    {...otherProps}
                    handleChangeDomains={handleChangeDomains}
                    questionData={questionData}
                />
        },
    ];

    // Handle tab change
    const handleTabChange = useCallback((tabId) => {
        setQuestionData(prevState => ({
            ...prevState,
            activeTab: tabId
        }));
    }, []);

    //submit Data
    const handleSubmitData = () => {
        const resultValidate = checkValidate();
        if (resultValidate) {
            let params = {
                Target: "Question",
                Action: questionViewActive === "Edit_Question" ? "Update" : "Create",
                TimePointId: questionData?.TimePointId,
                NewTimePointId: questionData?.NewTimePointId || questionData?.TimePointId,
                Task: questionData?.Task,
                QuestionContent: questionData?.QuestionContent,
                QuestionExplanation: questionData?.QuestionExplanation,
                QuestionType: questionData?.QuestionType,
                Domains: questionData?.Domains,
                FollowUpExplanationRequired: questionData?.FollowUpExplanationRequired,
                GradingSchema: questionData?.GradingSchema,
                AI_Instructions: questionData?.AI_Instructions,
                Scoring_Instructions: questionData?.Scoring_Instructions,
                PromptTemplateIds: questionData?.PromptTemplateIds,
                ImmediateFeedbackMode: questionData?.ImmediateFeedbackMode,
                Answers: questionData?.Answers
            };

            // Include QuestionId for updating
            if (questionViewActive === "Edit_Question") {
                params["QuestionId"] = itemQuestion?.QuestionId;
            }

            // Update FlowConditions for Question
            if (isCondition === "Question") {
                params["FlowConditions"] = [{
                    ConditionType: isCondition,
                    QuestionAnswers: flowControlOfQuestion?.QuestionAnswers,
                    ...flowControlOfQuestion
                }];
            }
            sendApiByType(id, params);
        }
    };

    // Send API with action
    const sendApiByType = (id, params) => {
        const actionType = questionViewActive === "Edit_Question" ? "UPDATE" : "CREATE";
        const actions = {
            request: teachConstants[`${actionType}_QUESTION_REQUEST`],
            success: teachConstants[`${actionType}_QUESTION_SUCCESS`],
            failure: teachConstants[`${actionType}_QUESTION_FAILURE`],
        };
        dispatch({ type: actions.request, isLoading: true });
        setLoading(true);
        const endpoint = `/api/teach/case/${id}/`;
        apiCaller(endpoint, 'PUT', params).then(res => {
            if (res?.status === 200) {
                const data = res?.data;
                dispatch({ type: actions.success, payload: data });
                setLoading(false);
                handleClickBack();
            } else {
                dispatch({ type: actions.failure, isLoading: false });
                setLoading(false);
            }
        });
    };

    //Validate
    const checkValidate = () => {
        if (!questionData?.QuestionContent) {
            toast.warning("Please enter a valid Question Text", {
                autoClose: 3000,
            });
            return false;
        } else {
            return true;
        }
    };

    // Convert data into a usable format for TabComponentBorder
    const tabs = tabsContent.map((question) => ({
        id: question.key,
        title: question.label,
        content: question.content,
    }));

    return (
        <>
            <HeaderFeature
                {...otherProps}
                title={questionViewActive === "Edit_Question" ? "Edit Question" : "Create Question"}
            />
            <TabComponentBorder
                tabs={tabs}
                activeTab={questionData.activeTab || 1}
                onTabChange={handleTabChange}
            />
            <div className='mt-4 text-right'>
                <Button
                    color="secondary"
                    className="px-5"
                    size='lg'
                    onClick={handleClickBack}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    className="ml-2 px-5"
                    size='lg'
                    onClick={handleSubmitData}
                    disabled={isLoading}
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    {questionViewActive === "Edit_Question" ? "Save" : "Add"}
                </Button>
            </div>
        </>
    );
};

export default CreateUpdateQuestion;