import React, { useState } from "react";
import { ModalResetCase } from '@components/common/ResetAndBack';
import { Iconsax } from "@components-common";

function ResetCaseButton(props) {
    const [isResetCaseOpen, setIsResetCaseOpen] = useState(false);

    const toggleResetCase = () => {
        setIsResetCaseOpen(!isResetCaseOpen);
    };

    const buttonSize = props.sizeButton ? `${props.sizeButton}px` : "52px";
    const buttonStyle = {
        backgroundColor: "white",
        width: props.sizeButton ? buttonSize : (props.width || buttonSize),
        height: props.sizeButton ? buttonSize : (props.height || buttonSize),
        borderRadius: "50%"
    };

    return (
        <React.Fragment>
            <button
                className="btn btn-reset-case mr-2"
                style={buttonStyle}
                onClick={toggleResetCase}
            >
                <Iconsax iconName="reset" fill="#4B5565" />
            </button>
            <ModalResetCase
                {...props}
                isOpen={isResetCaseOpen}
                toggle={toggleResetCase}
                isView="case"
            />
        </React.Fragment>
    );
}

export default ResetCaseButton;