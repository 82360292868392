import React from 'react'
import styled from 'styled-components'
import Icon from '@components-common/Icon'
import Back from '@images/teachV2/back.svg'
import stick from '@images/teachV2/stick-btn.svg'
import * as Config from "@constants/Config";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const WrapperButton = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding-top: 24px;
  .btn-next {
    padding: 14px 32px;
    font-size: 18px;
    font-weight: 500;
  }
`

const ButtonNext = styled.div`
  padding: 14px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  background: #0089C2;
  color: #fff;
  &.disabled {
    cursor: none;
    opacity: 0.6;
    pointer-events: none;
  }
`

const ButtonCancel = styled.div`
  padding: 14px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #D0D5DD;
  span {
    color: #475467;
    font-size: 18px;
    font-weight: 500;
  }
`

export default function ButtonSubmitCommon(props) {
  const { 
    handleNext, 
    handleBack, 
    step, 
    isGenerate, 
    handleShowImg, 
    dataPass, 
    parent, 
    dataForm, 
    getValues, 
    setDataContentFile, 
    setLoadingResult,
    loadingResult,
    setContentLast,
  } = props
  const handleContinue = (e) => {
    handleNext()
  }

  const handleSubmit = (data) => {
    const values = getValues();
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      if (key === "document_id" && value) {
        const documentIds = value?.map(doc => doc.document_id);
        const documentIdString = documentIds?.join(',');
        if (documentIdString) formData.append("document_id", documentIdString);
      } else if (key === "media_item_id" && value) {
        const documentIds = value?.map(doc => doc.id);
        const documentIdString = documentIds?.join(',');
        if (documentIdString) formData.append("media_item_id", documentIdString);
      } else {
        if (value && value !== 'none') formData.append(key, value);
      }
    }
    setLoadingResult(true);
    setContentLast(dataForm)

    axios({
      method: dataForm?.action?.api_call_mode,
      url: `${Config.API_URL}${dataForm?.action?.api}`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      onDownloadProgress: progressEvent => {
        const xhr = progressEvent.target;
        const { responseText } = xhr;
        let datasplit = responseText.split("event: question\ndata: ");
        setDataContentFile(
          datasplit?.map((data, index) => {
            return index > 0 && JSON.parse(data);
          })
        );
      }
    }).then(function (response) {
      let datasplit = response.data.split("event: question\ndata: ");
      setDataContentFile(
        datasplit?.map((data, index) => {
          return index > 0 && {
            id: index,
            ...JSON.parse(data)
          }
        })
      );
    }).catch(function (error) {
      console.error("Error in API call", error);
    }).finally(() => {
      setLoadingResult(false);
    });
  };

  return (
    <WrapperButton>
      <ButtonCancel 
        onClick={handleBack} 
        style={{ display: step === 1 ? 'none' : 'flex' }}
      >
        <Icon src={Back}/>
        <span>
          Back
        </span>
      </ButtonCancel>
      {isGenerate ? (
        <ButtonNext
          type="submit" 
          className="btn-generate"
          onClick={handleShowImg}
        >
          <Icon src={stick}/>
          Generate 
        </ButtonNext>
      ) : (
        parent?.Contents?.action?.api ? (
          <ButtonNext
            className="btn-next"
            color='primary'
            onClick={handleSubmit}
            disabled={loadingResult}
          >
            <span>Submit</span>
            {loadingResult && 
              <div className="ml-1">
                <ClipLoader color="#FFF" size={16}/>
              </div>
            }
          </ButtonNext>
        ) : (
          <ButtonNext 
            className={`btn-next ${dataPass ? "disabled" : ""}`} 
            onClick={handleContinue}
            color='primary'
            disabled={dataPass}
          >
            Continue
          </ButtonNext>
        )
      )}
    </WrapperButton>
  )
}
