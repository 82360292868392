import React from 'react';

//lodash
import _ from "lodash";

//Icon
import { Iconsax } from "@components-common";

function LevelHeader(props) {
    const { headingLevel, item } = props && props;
    const caseList = item?.Cases;
    const completedCount = _.filter(caseList, { 'Progress.status': 'completed' })?.length || 0;
    return (
        <div>
            <h3 className="headline --size-6">{headingLevel}</h3>
            <div className="d-flex algin-items-center mt-3">
                <span className="d-flex align-items-center">
                    <Iconsax iconName="notebook" fill={`#697586`} />
                    <span className="ml-2 content-gray">{`${completedCount}/${caseList?.length} cases complete`}</span>
                </span>
                {/* <span className="ml-4 d-flex align-items-center" style={{ marginLeft: '45px' }}>
                    <Iconsax iconName="watch" fill={`#697586`} />
                    <span className="ml-2 content-gray">{`3h 20m`}</span>
                </span> */}
            </div>
        </div>
    );
}

export default LevelHeader;