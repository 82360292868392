import React from 'react';
import { Card, CardHeader, CardBody, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

// Define reusable options
const options = [
    { value: 'model1', label: 'Model 1' },
    { value: 'model2', label: 'Model 2' },
    { value: 'model3', label: 'Model 3' },
    { value: 'model4', label: 'Model 4' },
];

// Define size configurations
const dropdownSizes = [
    { label: 'Dropdown Input (36 px)', className: 'react-select-common' },
    { label: 'Dropdown Input (44 px)', className: 'react-select-common react-select-md' },
    { label: 'Dropdown Input (56 px)', className: 'react-select-common react-select-lg' },
];

const DropdownInput = () => {
    return (
        <Card className="my-2">
            <CardHeader>Dropdown Input</CardHeader>
            <CardBody>
                {dropdownSizes.map((size, index) => (
                    <FormGroup key={index}>
                        <Label for={`modelSelect-${index}`}>{size.label}</Label>
                        <Select
                            id={`modelSelect-${index}`}
                            className={`${size.className} w-100`}
                            placeholder="Select item"
                            options={options}
                            classNamePrefix="react-select-custom"
                        />
                    </FormGroup>
                ))}
            </CardBody>
        </Card>
    );
};

export default DropdownInput;