import React, { useState } from 'react';
import styled from 'styled-components';

// Icon
import VitalIcon from '@images/learnerv2/vital-icon-sp.svg';
import ErmIcon from '@images/learnerv2/erm-icon-sp.svg';

//react-redux
import { useDispatch } from "react-redux";

//actions
import { toggleEMRForModalMobile } from "@actions";

//react-router-dom
import { useParams } from "react-router-dom";

import { PopupVitalSignMobile } from "@components/learner/StandardScreen/LearnerMobile";

const IconListContainerStyled = styled.div`
  position: absolute;
  top: 33px;
  right: 18px;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  z-index: 1;
  @media (min-width: 768px) {
    display: none; /* Ẩn trên các thiết bị không phải di động */
  }
`;

const IconItemStyled = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  background: rgba(0, 0, 0, 0.50);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:last-child {
    margin-right: 0;
  }
  
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`;

const IconListContainer = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [activePopup, setActivePopup] = useState(null);

  const handleIconClick = (popupType) => {
    setActivePopup(popupType);
  };

  const handleCloseModal = () => {
    setActivePopup(null);
  };
  
  return (
    <React.Fragment>
      <IconListContainerStyled>
        {parseInt(id) !== 754 && 
          <IconItemStyled onClick={() => handleIconClick('vitalSign')}>
            <img src={VitalIcon} alt="Vital Icon" />
          </IconItemStyled>
        }
        <IconItemStyled onClick={() => dispatch(toggleEMRForModalMobile(true))}>
          <img src={ErmIcon} alt="ERM Icon" />
        </IconItemStyled>
      </IconListContainerStyled>
      {activePopup === 'vitalSign' && <PopupVitalSignMobile {...props} onClose={handleCloseModal} />}
    </React.Fragment>
  );
};

export default IconListContainer;