import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { FormGroup, Label, Input } from "reactstrap";
import * as Yup from "yup";

//redux
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";

//partents
import { FormModal } from '@ui-partents/Modal';

//React Select
import { components } from "react-select";

// actions
import { editReferenceMedia } from "@actions";
import { shareMedia } from "@actions";

const ModalEditReference = ({
  title,
  isOpen,
  toggleModal,
  currentItem,
  loadDataReferenceBook,
  createOption,
}) => {
  const formikRef = useRef(null);
  const dispatch = useDispatch();

  // state
  const [listValue, setListValue] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [initialValues, setInitialValues] = useState({
    title: "",
    descriptions: "",
  });
  const [loading, setLoading] = useState(false);

  // Validation schema
  const validationSchema = Yup.object().shape({
    // title: Yup.string().required("Title is required"),
  });

  // Handle Click Save Button
  const handleSaveButtonClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const componentsCommon = {
    DropdownIndicator: () => null,
    MultiValueContainer: (props) => {
      return (
        <div className="custom-tag">
          <components.MultiValueContainer {...props} />
        </div>
      );
    },
  };

  const handleBlurAction = () => {
    if (inputValue) {
      setListValue((prev) =>
        prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]
      );
    }
  };

  const handleAddEmail = (value) => {
    setListValue(value);
  };

  useEffect(() => {
    if (currentItem) {
      setInitialValues({
        title: currentItem.title || "",
        descriptions: currentItem.descriptions || "",
      });
      const test =
        currentItem?.tags &&
        currentItem.tags?.map((tag) => {
          return {
            value: tag,
            label: tag,
          };
        });
      setListValue(test);
    }
  }, [currentItem]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setListValue((prev) =>
          prev
            ? [...prev, createOption(inputValue)]
            : [createOption(inputValue)]
        );
        setInputValue("");
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={title}
      onAction={() => handleSaveButtonClick()}
      actionText={"Save"}
      maxWidth="900px"
      iconConfig={{ iconName: "edit-modal", color: "#0089C2" }}
      isLoading={loading}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          const params = {
            Action: "Update",
            MediaItemId: currentItem.id,
            Title: values.title,
            Descriptions: values.descriptions,
          };
          dispatch(editReferenceMedia(params))
            .then(async (res) => {
              setLoading(false);
              if (res?.success) {
                const listDataTags = listValue?.map((data) => data?.value);
                if (currentItem?.tags?.length > 0) {
                  await dispatch(
                    shareMedia({
                      Action: "Untag",
                      MediaItemId: currentItem?.id,
                      Tags: currentItem?.tags,
                    })
                  );
                }
                if (listDataTags?.length > 0) {
                  await dispatch(
                    shareMedia({
                      Action: "Tag",
                      MediaItemId: currentItem?.id,
                      Tags: listDataTags,
                    })
                  );
                }
                loadDataReferenceBook();
                toggleModal(); // Close the modal on success
              } else {
                console.error("Error occurred:", res.error);
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error occurred:", error);
            });

          setSubmitting(false);
        }}
      >
        {({ values, errors, handleChange, handleBlur, touched }) => (
          <Form>
            <FormGroup>
              <Label className="labeForm" for="title">
                Media Name
              </Label>
              <Input
                type="text"
                name="title"
                id="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter document title"
              />
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="descriptions">
                Description
              </Label>
              <Input
                type="text"
                name="descriptions"
                id="descriptions"
                value={values.descriptions}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter descriptions"
              />
            </FormGroup>
            <FormGroup>
              <Label className="labeForm">
                Tag(s)
              </Label>
              <div className="email-share__form-input">
                <CreatableSelect
                  components={componentsCommon}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => handleAddEmail(newValue)}
                  onInputChange={(newValue) => setInputValue(newValue)}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlurAction}
                  placeholder="Type a tag, then press enter to add another"
                  value={listValue}
                  classNamePrefix={`select-tag`}
                />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </FormModal>
  );
};

export default ModalEditReference;
