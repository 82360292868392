import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// context
import { useScreenSize } from '@context/ScreenSizeContext';

// components
import { HeaderLeft, HeaderRight, ButtonCollapse } from ".";
import { ResetCaseButton } from '@components/learner/StandardScreen/CaseTask';
import { ModalCoinsEarned } from '@components/Coin';

// redux
import { useDispatch } from "react-redux";
import { actAccountInfo, getCoin } from "@actions";

const HeaderTop = (props) => {
    const { isSidebarCollapsed } = props;

    // Use screen size context
    const { isMobile } = useScreenSize();

    // Get the current location
    const location = useLocation();

    // Check if the current path contains 'learner'
    const isLearnerPath = location.pathname.includes('/learn/case');

    const dispatch = useDispatch();
    const [isAccountInfoLoaded, setIsAccountInfoLoaded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [coinReward, setCoinReward] = useState(null);

    useEffect(() => {
        const loadAccountInfo = async () => {
            try {
                const result = await dispatch(actAccountInfo());
                if (result?.status === 200) {
                    setIsAccountInfoLoaded(true);
                    const notifications = result?.data?.Notifications || [];
                    if (notifications?.length > 0) {
                        setCoinReward(notifications);
                        setIsModalOpen(true);
                    }
                } else {
                    console.warn("Failed to fetch account info, status:", result?.status);
                }
            } catch (error) {
                console.error("Error fetching account info:", error);
            }
        };
        loadAccountInfo();
    }, [dispatch]);

    useEffect(() => {
        if (isAccountInfoLoaded) {
            dispatch(getCoin());
        }
    }, [isAccountInfoLoaded, dispatch]);
    
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    return (
        <header className={`${isLearnerPath && isMobile  ? 'header-fixed' : ''}`}>
            <div className="container-fluid">
                <div className="row">   
                    <div className="col-lg-12 px-0">
                        <div className={`header_iner d-flex justify-content-between align-items-center ${isSidebarCollapsed ? 'collapsed' : ''} ${!isMobile ? 'bg-white' : ''}`}>
                            <div className="position-relative d-flex w-100 align-items-center justify-content-between">
                                {!isMobile && <ButtonCollapse {...props} />}
                                <HeaderLeft {...props} />
                                {isMobile && isLearnerPath && <ResetCaseButton backgroundColor="#F2F4F7" sizeButton="48" />}
                                {!isMobile && <HeaderRight {...props} />}
                            </div>
                        </div>
                    </div>
                </div>
                {coinReward && (
                    <ModalCoinsEarned
                        {...props}
                        isOpen={isModalOpen}
                        toggle={toggleModal}
                        rewardAmount={coinReward}
                    />
                )}
            </div>
        </header>
    );
};

export default HeaderTop;