import React from "react";
import styled from "styled-components";

// Styled components
const Button = styled.button`
  display: flex;
  padding: ${(props) => (props.isViewMobile ? "4px" : "14px")};
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background: #FFF;
  border: none;
  cursor: pointer;
  margin-right: 12px;
  height: ${(props) => (props.isViewMobile ? "30px" : "55px")};
`;

const Icon = styled.img`
  width: ${(props) => (props.isViewMobile ? "15px" : "32px")};
  height: ${(props) => (props.isViewMobile ? "15px" : "32px")};
`;

const Text = styled.span`
  color: #121926;
  font-size: ${(props) => (props.isViewMobile ? "12px" : "18px")};
  font-weight: 500;
  margin-left: ${(props) => (props.isViewMobile ? "12px" : "4px")};
  white-space: nowrap;
`;

const ButtonOnChatScreen = ({ icon, text, handleClick, isViewMobile }) => {
  return (
    <Button isViewMobile={isViewMobile} onClick={handleClick}>
      <Icon src={icon} alt="Button Icon" isViewMobile={isViewMobile} />
      <Text isViewMobile={isViewMobile}>{text}</Text>
    </Button>
  );
};

export default ButtonOnChatScreen;