import React, { useContext, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ThemeContext from "../../../context/ThemeContext";
import SidebarItem from "./SidebarItem";
import Logo from './Logo';
import { ModalFeedback } from "@components/teachV2/home/common";
import { Iconsax } from "@components-common";
import imgLogo from '@images/common/med2lab_logo.png';
import UserContext from '@context/UserContext';
import sidebarData from './sidebarItems.json';
import dropdownItemsData from './dropdownItems.json';

//UI common
import { CreateNewQuick } from "@ui-partents/Dropdown";

// Function to generate sidebar items based on path and user privileges
const generateSidebarItems = (pathname, privilege) => {
    let items = [];

    if (pathname.startsWith('/learn')) {
        items = sidebarData.learn;
    } else if (pathname.startsWith('/design-patterns')) {
        items = sidebarData.designPatterns;
    } else if (privilege === "alpha") {
        items = sidebarData.alpha;
    } else {
        items = sidebarData.default;
    }

    // Map the items to include Iconsax components
    return items.map(item => ({
        ...item,
        icon: <Iconsax iconName={item.icon} fill="#697592" />,
    }));
};

const SidebarMain = ({ isSidebarCollapsed }) => {
    const { pathname } = useLocation();
    const { privilege } = useContext(UserContext);
    const theme = useContext(ThemeContext);
    const primaryLogo = theme?.PrimaryLogo || imgLogo;

    const [modalOpen, setModalOpen] = useState({ feedback: false });

    const toggleModal = (type) => {
        setModalOpen(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const sidebarItems = useMemo(() => generateSidebarItems(pathname, privilege), [pathname, privilege]);

    const accountItems = [
        {
            icon: <Iconsax iconName="feedback" fill="#697592" />,
            name: 'Feedback',
            onClick: () => toggleModal("feedback")
        }
    ];

    return (
        <nav className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
            <Logo primaryLogo={primaryLogo} isSidebarCollapsed={isSidebarCollapsed} />
            {pathname.startsWith('/teach') && <CreateNewQuick menuData={dropdownItemsData || []} isSidebarCollapsed={isSidebarCollapsed} />}
            <ul className="sidebar-menu">
                {!isSidebarCollapsed && <p className="sidebar__title">MAIN MENU</p>}
                {sidebarItems.map((item, index) => !item.isHidden && (
                    <SidebarItem key={index} item={item} isSidebarCollapsed={isSidebarCollapsed} />
                ))}
            </ul>
            {!pathname.startsWith('/design-patterns') && (
                <div className="sidebar-menu of-account">
                    {!isSidebarCollapsed && <p className="sidebar__title">ACCOUNT</p>}
                    {accountItems.map((item, index) => (
                        <SidebarItem
                            handleClickAction={item.onClick}
                            key={index}
                            item={item}
                            isSidebarCollapsed={isSidebarCollapsed}
                        />
                    ))}
                </div>
            )}
            <ModalFeedback
                isOpen={modalOpen.feedback}
                toggle={() => toggleModal("feedback")}
                type="general"
            />
        </nav>
    );
};

export default SidebarMain;