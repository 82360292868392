import React from 'react';
import { Icon } from '@components/common'
import Empty from '@images/teachV2/empty.svg'

const MessageEmtyData = (props) =>{
    const { title, text } = props

    return (
        <div className="dataEmpty-container">
            <div className={`dataEmptyCase`}>
                <div className="dataEmptyCase__img">
                    <Icon src={Empty} />
                </div>
                <div className="dataEmptyCase__info">
                    <p className="dataEmptyCase__info-title">{title}</p>
                    <p className="dataEmptyCase__info-text">{text}</p>
                </div>
            </div>
        </div>
    );
};

export default MessageEmtyData;