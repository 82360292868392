import React, { useState, useEffect } from 'react';

//components
import { InputFreeText, UserMessage, PatientMessage } from '../../Chat/FreeText';
import { UserActive } from "../../Chat";
import { LoadingMessage } from "../FreeText";
import { QuestionEndChat, InstructorMessage } from "../../Chat/EndChat";

//redux
import { useSelector } from "react-redux";

//default
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";
import imgUser from '@images/common/placehoder-avatar.svg';

import styled, { keyframes } from 'styled-components';

// Keyframes for fadeInUp animation
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled-component for questionBottom
const QuestionBottom = styled.div`
  background-color: white;
  animation: ${fadeInUp} 0.5s ease-in-out;
  position: relative;
`;

function Framechat(props) {
    const {
        chatRecord,
        messagesEndRef,
        heightFooterChat,
        isStreamDataLoading,
        isShowForSwitch,
        ChatMode,
        endChatFeedbackData,
        endChatData,
        divRef,
        profile_photo_url
    } = props;

    //Question - Instructor
    const AnswerChoices = endChatData?.Question?.AnswerChoices;

    //Case 
    const caseReducer = useSelector((state) => state.cases || []);
    const caseResponse = caseReducer?.data?.Case;
    const ChatSettings = caseResponse.ChatSettings;
    const ChatLocked = ChatSettings?.ChatLocked;

    //state
    const [heightFoot, setHeightFoot] = useState(0);

    // Caculator Height
    useEffect(() => {
        setHeightFoot(heightFooterChat)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heightFooterChat])

    //state
    const [optionsData, setOptionsData] = useState({
        SelectOne: AnswerChoices || null
    });
    const [valueOptions, setvalueOptions] = useState(null);

    //Load Data While Page Load
    useEffect(() => {
        setOptionsData(prevState => ({
            ...prevState,
            SelectOne: AnswerChoices || null
        }));
    }, [AnswerChoices])

    const showSwitchVoiceText = isShowForSwitch ? "d-block" : "d-none";
    const isDisabled = endChatFeedbackData?.Mode === "InstructorFeedback" || ChatLocked;

    //Handle Select One - Mutiple Choice
    const handleSelectOne = (data, e) => {
        const { checked, name } = e.target;
        const newDataChoices = [...optionsData?.SelectOne];
        let index = newDataChoices.findIndex((value) => value.Id === data.Id);
        if (name === "Selected") {
            newDataChoices[index]["Selected"] = checked;
        }

        // Show data for input
        displaySelectedItemsInInput(newDataChoices);

        //setData
        setOptionsData((prevState) => ({ ...prevState, SelectOne: newDataChoices || [] }));
    };

    const displaySelectedItemsInInput = (newDataChoices) => {
        const selectedItems = newDataChoices.filter(item => item.Selected).map(item => item.Content).join(", ");
        setvalueOptions(selectedItems);
    }

    //Characters
    const Characters = caseResponse?.Characters;
    const Patient = Characters?.Patient;
    const Instructor = Characters?.Instructor;
    const PatientCharacter = Patient?.Character;
    const InstructorCharacter = Patient?.Character;
    const patientNameText = Patient?.DisplayName;
    const preceptorNameText = Instructor?.DisplayName;
    const photoPatient = PatientCharacter?.StandardSquarePhoto || imgUser;
    const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;
    const userAvatar = profile_photo_url || imgUser;
    return (
        <React.Fragment>
            <div className={`frameChat__fullBox interact_panel position-relative ${ChatMode === "switch_voice_text" ? showSwitchVoiceText : ""}`}>
                {chatRecord?.length === 0 && <UserActive userNameActive={patientNameText} RoleDefault={`Patient`} image={photoPatient} />}
                <div className="messengerCt scrollbarStyle" ref={messagesEndRef} style={{ height: `calc(100vh - ${heightFoot}px)` }}>
                    <div className="messengerCt__messages">
                        {chatRecord?.map((value, index) => (
                            <React.Fragment key={index}>
                                {value?.Role === "User" &&
                                    <UserMessage
                                        {...props}
                                        content={value?.Content}
                                        userAvatar={userAvatar}
                                    />
                                }
                                {value?.Role === "Patient" &&
                                    <PatientMessage
                                        {...props}
                                        index={index}
                                        values={value}
                                        patientNameText={patientNameText}
                                        photoPatient={photoPatient}
                                    />
                                }
                                {value?.Role === "Instructor" &&
                                    <InstructorMessage
                                        {...props}
                                        index={index}
                                        content={value?.Content?.Question?.Prompt}
                                        preceptorNameText={preceptorNameText}
                                        PreceptorAvatar={PreceptorAvatar}
                                    />
                                }
                                {chatRecord?.length - 1 === index && isStreamDataLoading &&
                                    <LoadingMessage
                                        {...props}
                                        patientNameText={patientNameText}
                                        photoPatient={photoPatient}
                                    />}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <QuestionBottom ref={divRef}>
                    {endChatData?.Mode === "ShowQuestion" &&
                        <QuestionEndChat
                            {...props}
                            AnswerChoices={AnswerChoices}
                            handleSelectOne={handleSelectOne}
                        />
                    }
                    {!isDisabled && <InputFreeText {...props} valueOptions={valueOptions} disabled={isDisabled} />}
                </QuestionBottom>
            </div>
        </React.Fragment>
    );
}

export default Framechat;