import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

//components
import { UserMessage, InstructorMessage, LoadingMessage } from '@components/learner/StandardScreen/Chat/Preceptor';

//images
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";
import imageDefault from "@images/common/person.png";

//react-redux
import { useSelector } from "react-redux";

// Styled-component for the fade-in effect
const MessageWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease;

  &.fadeIn {
    opacity: 1;
  }
`;

function Framechat(props) {
    //props
    const { chatRecord, messagesEndRef, isLoading, heightFooterChat, profile_photo_url } = props;

    //state
    const [calculatedHeight, setCalculatedHeight] = useState(heightFooterChat || 0);
    const [fadeInIndexes, setFadeInIndexes] = useState([]);

    // Update height based on chat record
    useEffect(() => {
        setCalculatedHeight(heightFooterChat);
    }, [heightFooterChat]);

    // Set fade-in effect for messages
    useEffect(() => {
        const newFadeInIndexes = chatRecord.map((_, index) => index);
        setFadeInIndexes(newFadeInIndexes);
    }, [chatRecord]);

    const heightBottom = `calc(100vh - ${calculatedHeight}px)`;

    //Redux Store
    const caseData = useSelector((state) => state.cases || []);
    const caseResponse = caseData?.data?.Case;

    //Characters - Instructor
    const Characters = caseResponse?.Characters;
    const Instructor = Characters?.Instructor;
    const preceptorNameText = Instructor?.DisplayName;
    const InstructorCharacter = Characters?.Instructor;
    const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;
    const userAvatar = profile_photo_url || imageDefault;
    return (
        <MessageWrapper className="messengerCt scrollbarStyle fadeIn" ref={messagesEndRef} style={{ height: heightBottom }}>
            <div className="messengerCt__messages">
                {chatRecord?.map((value, index) => (
                    <React.Fragment key={index}>
                        <MessageWrapper className={fadeInIndexes.includes(index) ? "fadeIn" : ""}>
                            {value?.Role === "Instructor" && (
                                <InstructorMessage
                                    {...props}
                                    index={index}
                                    value={value}
                                    PreceptorAvatar={PreceptorAvatar}
                                    preceptorNameText={preceptorNameText}
                                />
                            )}

                            {value?.Role === "User" && (
                                <UserMessage
                                    {...props}
                                    value={value}
                                    userAvatar={userAvatar}
                                />
                            )}
                        </MessageWrapper>

                        {/* Loading Message at the End of Chat */}
                        {chatRecord?.length - 1 === index && (isLoading?.streamData || isLoading?.submitQuestion) && (
                            <LoadingMessage
                                {...props}
                                PreceptorAvatar={PreceptorAvatar}
                                preceptorNameText={preceptorNameText}
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
        </MessageWrapper>
    );
}

export default Framechat;