import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

//imgUser
import imgUser from '@images/common/placehoder-avatar.svg';

//common
import { DataEmty } from "@components-common";
import { ButtonDelete, ButtonEdit, ButtonAdd } from '@components-common/elements';
import { ModalDeleteCommon } from "@ui-partents/Modal";
import { ModalEditAvatar, ModalAddAvatar } from "../Avatars";
import { LoaderPage } from "@utils";

// Import the pagination configuration
import { paginationOptions } from "@utils";

//format time
import moment from "moment";

//message
import { messageData, apiCaller } from "@utils";

//components
import { Filter } from "../Avatars";

function TableAvatars(props) {
  //state
  const [stateData, setData] = useState({});
  const [rowRecord, setRowRecord] = useState({});
  const [modal, setModal] = useState({
    addAvatar: false,
    deleteAvatar: false,
    editAvatar: false
  });
  const [isLoading, setLoading] = useState({
    getAllAvatars: false,
    addAvatar: false,
    deleteAvatar: false,
    editAvatar: false
  });

  //Filter
  const [defaultFilter, setDefaultFilter] = useState({});
  const [filter, setFilter] = useState({
    level: '',
    gender: '',
    roletype: ''
  });

  //load api
  useEffect(() => {
    const fetchData = () => {
      const params = { "action_type": "get_all_avatars" }
      setLoading((prevState) => ({ ...prevState, getAllAvatars: true }));
      //Call Api Load Text Item
      apiCaller(`/api/character/avatar/`, "POST", params).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          setData(data);
          setLoading((prevState) => ({ ...prevState, getAllAvatars: false }));
        } else {
          console.log("error....");
          setLoading((prevState) => ({ ...prevState, getAllAvatars: false }));
        }
      })
    }
    const fetchDefaultTags = () => {
      const params = {"action_type": "get_default_tags"}
      //Call Api Load Text Item
      apiCaller(`/api/character/avatar/`, "POST", params).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          setDefaultFilter(data);
        } else {
          console.log("error....");
        }
      })
    }
    fetchDefaultTags();
    fetchData();
  }, []);


  //toggle
  const toggle = (type, value) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setRowRecord(value);
  };

  const columns = [
    {
      dataField: "avatar_id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      editable: false,
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      formatter: (value, row) => {
        return (
          <div className="userBox">
            <div className="userBox__left">
              <img src={row?.image_url || imgUser} className="imgUser bg-grey-light" alt="img" />
            </div>
            <div className="userBox__right">
              {/* <p className="m--font-brand branchText">{`email`}</p> */}
              <p className="userName">
                {row?.name}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "gender",
      text: "Gender",
      editable: true,
      headerClasses: "align-middle font-weight-500 text-center",
      classes: "align-middle text-center",
      formatter: (value, row) => {
        return <div>{row?.tags?.gender}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "roletype",
      text: "Role",
      headerClasses: "align-middle font-weight-500 text-center",
      classes: "align-middle text-center",
      formatter: (value, row) => {
        return <div>{row?.tags?.roletype}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "level",
      text: "Level",
      headerClasses: "align-middle font-weight-500 text-center",
      classes: "align-middle text-center",
      formatter: (value, row) => {
        return <div>{row?.tags?.level}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "created_at",
      text: "Submitted Date",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      sort: true,
      editable: false,
      formatter: (value, row) => {
        return (
          <span>{moment(value).format('YYYY-MM-DD')}</span>
        );
      },
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      }
    },
    {
      dataField: "description",
      text: "Description",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle ",
      editable: false
    },
    {
      dataField: "Actions",
      text: "",
      editable: false,
      headerClasses: "font-weight-400 align-middle",
      classes: "align-middle text-center text-nowrap",
      style: {
        width: "50px",
      },
      formatter: (cell, row) => {
        return (
          <React.Fragment>
            <ButtonEdit handleEdit={() => toggle("editAvatar", row)} />
            <ButtonDelete handleDelete={() => {
              toggle("deleteAvatar", row);
            }} />
          </React.Fragment>
        );
      },
    }
  ];

  //Handle Remove Avatar
  const handleRemoveAvatar = () => {
    const params = {
      "action_type": "delete_avatar",
      "avatar_id": rowRecord?.avatar_id
    }

    setLoading((prevState) => ({ ...prevState, deleteAvatar: true }));
    //Call Api
    apiCaller(`/api/character/avatar/`, "POST", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {

        //Update Data
        handleUpdateData(data);

        //Close Modal
        setLoading(prevState => ({ ...prevState, deleteAvatar: false }));
        setModal((prevState) => ({ ...prevState, deleteAvatar: false }));
      } else {
        console.log('error');
        //Close Modal
        setLoading((prevState) => ({ ...prevState, deleteAvatar: false }));
        setModal((prevState) => ({ ...prevState, deleteAvatar: false }));
      }
    });
  }

  //Update Data
  const handleUpdateData = (data) => {
    setData((prevState) => ({ ...prevState, all_avatars: data?.all_avatars }));
  }

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value
    }));
  };

  const filteredData = stateData?.all_avatars?.filter((avatar) => {
    const { level, gender, roletype } = filter;

    if (level && avatar.tags.level !== level) {
      return false;
    }

    if (gender && avatar.tags.gender !== gender) {
      return false;
    }

    if (roletype && avatar.tags.roletype !== roletype) {
      return false;
    }

    return true;
  });

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <div className="text-right mb-4">
          <ButtonAdd handleAdd={() => toggle('addAvatar', {})} />
        </div>
        <ToolkitProvider
          keyField="avatar_id"
          data={filteredData || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <BootstrapTable
              condensed
              wrapperClasses="table-responsive"
              keyField="avatar_id"
              data={filteredData || []}
              columns={columns}
              noDataIndication={() => <DataEmty content={messageData?.filter} />}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          )}
        </ToolkitProvider>
        <div className="pagination-custom-case d-flex justify-content-center mt-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  const totalSize = filteredData?.length || 0; // Dynamically calculate the total size
  
  // Use the paginationOptions function to get the configuration
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <React.Fragment>
      {isLoading?.getAllAvatars && <LoaderPage />}
      <Container className="-box-shadow bg-white p-4">
        <Filter 
          {...props}
          handleChange={handleFilterChange}
          filter={filter || {}}
          defaultFilter={defaultFilter}
        />
        <PaginationProvider pagination={paginationFactory(paginationConfig)}>
          {contentTable}
        </PaginationProvider>
        <ModalDeleteCommon
          title={`Are you sure you want to delete this record ?`}
          sub={`This action is not reversible. The reference will be permanently deleted from the system.`}
          isOpen={modal?.deleteAvatar}
          toggle={() => toggle('deleteAvatar', rowRecord)}
          handleRemove={handleRemoveAvatar}
          isLoading={isLoading?.deleteAvatar}
        />
        <ModalAddAvatar
          {...props}
          isOpen={modal?.addAvatar}
          toggle={() => toggle('addAvatar', {})}
          isLoading={isLoading?.addAvatar}
          handleUpdateData={handleUpdateData}
          defaultFilter={defaultFilter}
        />
        <ModalEditAvatar
          {...props}
          isOpen={modal?.editAvatar}
          toggle={() => toggle('editAvatar', rowRecord)}
          isLoading={isLoading?.editAvatar}
          rowRecord={rowRecord}
          handleUpdateData={handleUpdateData}
          defaultFilter={defaultFilter}
        />
      </Container>
    </React.Fragment>
  );
}

export default TableAvatars;
