import React, { useState } from 'react'
import McqWaiting from '@images/teachV2/waiting-mcq.gif'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ResultItem from './ResultItem'
import { ModalFeedback } from '../common'

const ResultGenerate = (props) => {
  const { loadingResult, dataContentFile } = props
  const [showModal, setShowModal] = useState(false)
  const [dataFeedback, setDataFeedback] = useState(false)
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="inputGenerate inputGenerate-result h-100">
      <div className="inputGenerate__form">
        <div className='inputGenerate__form-result w-100'>
          {dataContentFile?.length ? (
            <>
              <div className='inputGenerate__form-tabs'>
                {dataContentFile?.map((tab, index) => (
                  tab?.id > 0 &&
                    <div 
                      key={index}
                      className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
                      onClick={() => handleTabChange(tab.id)}
                    >
                      <p>Version {tab?.id}</p>
                    </div>
                ))}
              </div>
              {dataContentFile?.map((question, index) => (
                question?.question && 
                  <ResultItem 
                    key={index} 
                    setShowModal={setShowModal} 
                    index={index} 
                    question={question} 
                    setDataFeedback={setDataFeedback} 
                    dataContentFile={dataContentFile} 
                    activeTab={activeTab}
                  />
              ))}
              {loadingResult && 
                <div className='question-item'>
                  <Skeleton height={120} width={`100%`} />
                  <Skeleton height={20} width={`75%`} />
                  <Skeleton height={20} width={`60%`} />
                  <Skeleton height={20} width={`80%`} />
                  <Skeleton height={20} width={`55%`} />
                </div>
              }
            </>
          ) : (
            <>
              {loadingResult ? (
                <div className='question-item'>
                  <Skeleton height={120} width={`100%`} />
                  <Skeleton height={20} width={`75%`} />
                  <Skeleton height={20} width={`60%`} />
                  <Skeleton height={20} width={`80%`} />
                  <Skeleton height={20} width={`55%`} />
                </div>
              ) : (
                <div className='content-generate'>
                  <div className="content-generate__header">
                    {/* <Icon src={LoadingIcon} height={48} width={48}/> */}
                    <img src={McqWaiting} alt="mcq" />
                    <p>Getting Started with MCQ Generator</p>
                  </div>
                  <div className="content-generate__steps">
                    <div className="content-generate__steps-item">
                      <p className='item-title'>Step 1: Enter Your Instructions</p>
                      <ul>
                        <li>Describe the topic, learning objectives, target learners, or any specific details you want the AI to consider.</li>
                      </ul>
                    </div>
                    <div className="content-generate__steps-item">
                      <p className='item-title'>Step 2: Choose a Template</p>
                      <ul>
                        <li>Select a suitable template from the available options. The AI will use this template as a guide for creating your MCQ.</li>
                      </ul>
                    </div>
                    <div className="content-generate__steps-item">
                      <p className='item-title'>Step 3: Attach a Document</p>
                      <ul>
                        <li>Upload any relevant files, such as lectures or reference materials, to provide the AI with more context for generation.</li>
                        <li>Supported formats include PDF, PPT, Word, TXT.</li>
                      </ul>
                    </div>
                    <div className="content-generate__steps-item">
                      <p className='item-title'>Step 4: Generate</p>
                      <ul>
                        <li>Click ‘Generate’ and wait a few seconds for your MCQs to be created.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ModalFeedback
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        type="mcq_draft"
        dataFeedback={dataFeedback}
      />
    </div>
  )
}

export default ResultGenerate
