import React from 'react';
import styled, { css } from 'styled-components';
import CloseIcon from '@images/message/close-modal.svg';
import VoiceNotSupportIcon from '@images/message/voice-not-support.svg';

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 17000;
  opacity: 0; /* Initial state for animation */
  visibility: hidden; /* Initially hidden */
  transition: opacity 0.3s ease, visibility 0.3s ease;

  ${(props) =>
    props.isOpen &&
    css`
      opacity: 1; /* Fully visible when open */
      visibility: visible; /* Make it visible */
    `}
`;

const CenteredBox = styled.div`
  display: inline-flex;
  padding: 24px;
  margin: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: 442px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  transform: translateY(-20px); /* Initial state for animation */
  transition: transform 0.3s ease;

  ${(props) =>
    props.isOpen &&
    css`
      transform: translateY(0); /* Move to original position when open */
    `}
`;

const IconWrapper = styled.div`
  width: 81px;
  height: 81px;
  margin-bottom: 16px;
`;

const CloseIconButton = styled.button`
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const Heading = styled.h2`
  color: #101828;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.48px;
  margin-bottom: 8px;
`;

const Description = styled.p`
  color: #1D2939;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const ModalMessageVoice = ({ heading, description, handleClose, isOpen }) => {
  return (
    <OverlayWrapper isOpen={isOpen}>
      <CenteredBox isOpen={isOpen}>
        <CloseIconButton onClick={handleClose}>
          <img src={CloseIcon} alt="Close modal" />
        </CloseIconButton>
        <IconWrapper>
          <img src={VoiceNotSupportIcon} alt="Voice Chat Not Supported icon" width="81" height="81" />
        </IconWrapper>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
      </CenteredBox>
    </OverlayWrapper>
  );
};

export default ModalMessageVoice;