import React, { useState } from "react";

//Boostrap
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//components
import { DragDropZoneFile } from "@ui-partents/Form/FormUpload";

const ModalUpload = (props) => {
  //props
  const { isOpen, toggle, onSubmitUpload, isLoading } = props && props;

  //state
  const [imageData, setImageData] = useState([]); //files
  const [urlImage, setUrlImage] = useState("");

  //handleUploadImage
  const handleUploadImage = (data) => {
    //Set Image Preview
    data.map((file) => setUrlImage(URL.createObjectURL(file)));

    //Set File FormData
    data.map((file) => setImageData(file));
  };

  const onSubmit = () => {
    onSubmitUpload(imageData);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalHeader toggle={toggle}>Change Photo</ModalHeader>
      <ModalBody>
        <DragDropZoneFile onChange={handleUploadImage} value={urlImage} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="danger"
          className="btn btn-brand --bg-primary d-flex align-items-center"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalUpload;
