import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from "react-redux";
import {
  InputFreeText,
  QuestionSelectMany,
  QuestionSelectOne,
  ButtonNextQuestion,
  MessageBoxEndChat,
} from '@components/learner/StandardScreen/Chat/Preceptor';

// Keyframes for fadeInUp animation
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled component for the fade-in effect
const FadeInWrapper = styled.div`
  animation: ${fadeInUp} 0.6s ease-in-out;
`;

const QuestionContainer = (props) => {
  const {
    divRef,
    nextQuestion,
    TaskToResume,
    SubType,
    Type,
    optionsData,
    handleSelectMany,
    handleSelectOne,
    handleSubmitQuestion,
    valueOptions,
    isMessageLoader,
    handleNextQuestion
  } = props;

  // Get the question data and loading state from the Redux store
  const questionData = useSelector((state) => state.question || {});
  const QuestionResponse = questionData?.data?.Question;

  return (
    <div ref={divRef}>
      {!nextQuestion && (
        <div className={`questionBottom ${nextQuestion ? "mx-auto" : "bg-white"}`}>
          {!TaskToResume && (
            <React.Fragment>
              {SubType === "select_many" && Type === "multiple_choice" && (
                <QuestionSelectMany
                  {...props}
                  handleSelectMany={handleSelectMany}
                  QuestionResponse={QuestionResponse}
                  AnswerChoices={optionsData?.SelectMany}
                />
              )}
              {SubType === "select_one" && Type === "multiple_choice" && (
                <QuestionSelectOne
                  {...props}
                  handleSelectOne={handleSelectOne}
                  QuestionResponse={QuestionResponse}
                  AnswerChoices={optionsData?.SelectOne}
                />
              )}
              {QuestionResponse &&
                <InputFreeText
                  {...props}
                  handleSubmitChoices={handleSubmitQuestion}
                  handleSubmitFreeText={handleSubmitQuestion}
                  isLoading={questionData?.isLoading?.submitQuestion}
                  valueOptions={valueOptions}
                  isMessageLoader={isMessageLoader}
                  disabled={Type === "multiple_choice"} //Chọn kiểu này thì chỉ show data
                />
              }
            </React.Fragment>
          )}
        </div>
      )}

      {/* Apply fadeInUp animation to MessageBoxEndChat and TaskToResume */}
      {TaskToResume && (
        <FadeInWrapper>
          <MessageBoxEndChat
            {...props}
            TaskToResume={TaskToResume}
          />
        </FadeInWrapper>
      )}

      {nextQuestion && !TaskToResume && (
        <FadeInWrapper>
          <ButtonNextQuestion
            {...props}
            handleNextQuestion={handleNextQuestion}
            isLoading={questionData?.isLoading?.nextQuestion}
          />
        </FadeInWrapper>
      )}
    </div>
  );
};

export default QuestionContainer;