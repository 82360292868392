import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Row, Col } from "reactstrap";

//actions
import { atcLoadLaunchPlans } from "@actions";
import { atcDeleteLaunchPlan } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//common
import { DataEmty, LockMessage } from "@components-common";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';
import { ModalDeleteCommon } from "@ui-partents/Modal";

//components
import { ModalAdd, ModalDetails, FilterStatus, BtnExportCSV } from "../LaunchPlans";

//lodash
import _ from "lodash";

function LaunchPlan(props) {

  //redux
  const dispatch = useDispatch();
  const storeLaunchPlans = useSelector(
    (state) => state.loadAllLaunchPlans || []
  );
  const dataLaunchPlans = storeLaunchPlans?.data;
  const isLocked = dataLaunchPlans?.Access;
  const dataDefault = _.filter(dataLaunchPlans, { 'Status': "Active" });

  //state action, modal
  const [actionClick, setActionClick] = useState("");
  const [itemObject, setObject] = useState({});
  const [isStatus, setStatus] = useState('Active');
  const [stateData, setData] = useState((dataDefault && dataDefault) || []);
  const [modal, setModal] = useState({
    add: false,
    delete: false,
    edit: false,
    details: false,
  });


  const toggle = (data, type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setActionClick(type);
    setObject(data);
  };

  //Load Launch Plans
  useEffect(() => {
    dispatch(atcLoadLaunchPlans());
  }, [dispatch]);

  //Load Data
  useEffect(() => {
    const dataDefault = _.filter(dataLaunchPlans, { 'Status': "Active" });
    setData((dataDefault && dataDefault) || []);
  }, [dataLaunchPlans])

  //handle Change Status
  const handleChange = (e) => {
    const { value } = e.target;
    if (dataLaunchPlans && value) {
      const newData = _.filter(dataLaunchPlans, { 'Status': value });
      setStatus(value);
      setData((newData && newData) || []);
    } else {
      setData((dataLaunchPlans && dataLaunchPlans) || []);
    }
  }

  //handle RefLink Active
  const handleChangeRefLink = (type) => {
    if (type) {
      const newData = _.filter(dataLaunchPlans, { 'Status': type });
      setStatus(type);
      setData(newData || []);
    } else {
      setData(dataLaunchPlans || []);
    }
  }

  const columns = [
    {
      dataField: "LaunchPlanId",
      text: "ID",
      hidden: true,
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "Name",
      text: "Name",
      headerClasses: "--text-primary font-weight-500",
      style: {
        width: "20%",
      },
      formatter: (value, row) => <span>{value}</span>,
    },
    {
      dataField: "Series",
      text: "Series",
      headerClasses: "--text-primary font-weight-500",
      style: {
        width: "20%",
      },
      formatter: (value, row) => <span>{value.Name}</span>,
    },
    {
      dataField: "Cohort",
      text: "Cohort",
      headerClasses: "--text-primary font-weight-500",
      style: {
        width: "20%",
      },
      formatter: (value, row) => <span>{value.CohortName}</span>,
    },
    {
      dataField: "Period",
      text: "Period",
      headerClasses: "--text-primary font-weight-500",
      style: {
        width: "20%",
      },
      formatter: (value, row) => <span>{value.PeriodName}</span>,
    },
    {
      dataField: "Status",
      text: "Status",
      headerClasses: "--text-primary font-weight-500 text-center",
      classes: "align-middle text-center",
      style: {
        width: "10%",
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "Actions",
      text: "",
      style: {
        width: "10%",
        whiteSpace: "nowrap",
      },
      headerClasses: "font-weight-400",
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <Button
              className="btn btn-brand btn-sm mr-2"
              color="info"
              onClick={() => toggle(row, "details")}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </Button>
            <BtnExportCSV items={row} />
            <ButtonEdit handleEdit={() => toggle(row, "edit")} />
            <ButtonDelete handleDelete={() => toggle(row, "delete")} />
          </div>
        );
      },
    },
  ];

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      LaunchPlanId: itemObject?.LaunchPlanId,
    };
    dispatch(atcDeleteLaunchPlan(params));
    toggle({}, "delete");
  };

  const totalDraft = dataLaunchPlans && _.filter(dataLaunchPlans, { 'Status': "Draft" })?.length;
  const totalExpired = dataLaunchPlans && _.filter(dataLaunchPlans, { 'Status': "Expired" })?.length;
  return (
    <React.Fragment>
      {isLocked && isLocked === "Locked" && (
        <LockMessage {...props} data={(stateData && stateData) || []} />
      )}
      {!isLocked && (
        <React.Fragment>
          <Row className="mb-4">
            <Col md={6}>
              <div className="d-flex align-items-center flex-wrap">
                <FilterStatus
                  handleChange={handleChange}
                  value={isStatus}
                />
                <p className="mt-2">There are <span onClick={() => handleChangeRefLink('Draft')} style={{ cursor: 'pointer', color: 'blue' }} className="font-weight-500 refUnderline">{totalDraft || 0} draft</span> and <span className="font-weight-500 refUnderline" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleChangeRefLink('Expired')}>{totalExpired || 0} expired</span> launch plans</p>
              </div>
            </Col>
            <Col md={6}>
              <Button
                className="--btn-primary d-flex align-items-center ml-auto"
                onClick={() => toggle({}, "add")}
              >
                <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add New
              </Button>
            </Col>
          </Row>
          <BootstrapTable
            keyField="LaunchPlanId"
            condensed
            striped
            wrapperClasses="table-responsive"
            data={stateData || []}
            columns={columns}
            noDataIndication={() => <div className="py-5"><DataEmty content="No Data" /></div>}
          />
        </React.Fragment>
      )}
      <ModalAdd
        {...props}
        isOpen={actionClick === "add" ? modal.add : modal.edit}
        toggle={() => toggle({}, actionClick)}
        action={actionClick}
        item={itemObject}
      />
      <ModalDeleteCommon
        title={`Are you sure you want to delete this launch plan <i>${itemObject?.Name}</i> / This action is not reversible.`}
        sub={`The launch plan will be canceled.`}
        isOpen={modal.delete}
        toggle={() => toggle({}, "delete")}
        handleRemove={handleRemove}
      />
      <ModalDetails
        isOpen={modal.details}
        toggle={() => toggle({}, "details")}
        action={actionClick}
        item={itemObject}
      />
    </React.Fragment>
  );
}

export default LaunchPlan;