import React, { useState } from 'react';

//components
import { ViewMediaButton } from '../../Chat';
import { FlagModal } from "../FreeText";
import { ModalDiscussThisQuestion } from "@components/common/Feedback";

//Email components
import { BtnViewEmail } from "../../EmailTemplate";

function PatientMessage(props) {
    const { loadingIndex, index, values, photoPatient, patientNameText } = props && props;
    //state Modal
    const [modal, setModal] = useState({
        DiscussThisQuestion: false
    });

    //Toggle Modal
    const toggle = (type, item, data) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const mediaData = values?.FullQuestionResponse?.Media;
    const ShowEmail = values?.FullQuestionResponse?.ShowEmail;
    return (
        <React.Fragment>
            <div className={`messengerCt__wrapper isShow`} data-index={loadingIndex === index}>
                <div className="messengerCt__message messengerCt__message--in">
                    <div className="messengerCt__message-pic">
                        <img
                            src={photoPatient}
                            alt={patientNameText}
                        />
                    </div>
                    <div className="messengerCt__message-body position-relative">
                        <div className={`messengerCt__message-content --arrow-left`}>
                            <FlagModal
                                {...props}
                                toggle={toggle}
                            />
                            <div className="messengerCt__message-username">
                                <div class="role-chat">{`${patientNameText || "Patient"}:`}</div>
                            </div>
                            <div className="messengerCt__message-text">
                                <React.Fragment>
                                    <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: values?.Content }} />
                                    {mediaData && <ViewMediaButton values={mediaData} />}
                                    {ShowEmail && <BtnViewEmail {...props} values={values} />}
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDiscussThisQuestion
                isOpen={modal?.DiscussThisQuestion}
                toggle={() => toggle("DiscussThisQuestion")}
                handleClose={() => toggle("DiscussThisQuestion")}
                type="chat"
                // currentChatData={value}
                // questionId={questionId}
            />
        </React.Fragment>
    );
};

export default PatientMessage;