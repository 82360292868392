import React from 'react';

//reactstrap
import { Row, Col } from "reactstrap";

function AccordionFilter(props) {
    const { searchText, onSearch, toggleCategory} = props && props;
    return (
        <Row className="justify-content-end">
            <Col md={6}>
                <div className="boxSearch mb-3">
                    <input
                        type="text"
                        className="form-control"
                        onChange={onSearch}
                        value={searchText}
                        placeholder="Search"
                        autoComplete="off"
                    />
                    <button className="--bg-primary btnSearch btn btn-primary">
                        <i className="fa fa-search" aria-hidden="true" />
                    </button>
                </div>
            </Col>
            <Col md={6} className="mb-3 mb-md-0">
                <button
                    className="btn ml-auto btn-outline-brand"
                    onClick={() => toggleCategory("addCategory", {})}
                >
                    Add Category
                </button>
            </Col>
        </Row>
    );
};

export default AccordionFilter;