import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { MessageEmtyData } from "@ui-partents/Message";
import { paginationOptions } from "@utils";
import ReactTooltip from 'react-tooltip';
//Icon
import { Icon } from '@components/common';
import More from '@images/teachV2/more-vertical.svg';
import ColumnLink from './ColumnLink';

// Generate demo data
const generateDemoData = (count) => {
  const firstNames = ['John', 'Jane', 'Samuel', 'Alice', 'Bob', 'Charlie', 'Eve', 'Frank', 'Grace', 'Hank', 'Ivy', 'Jack', 'Karen', 'Leo', 'Mona', 'Nina', 'Oscar', 'Paul', 'Quinn', 'Rita', 'Steve', 'Tina', 'Uma', 'Victor', 'Wendy', 'Xander', 'Yara', 'Zane', 'Amy', 'Brian', 'Clara', 'Derek', 'Eleanor', 'Felix', 'Gina', 'Hugo', 'Isla', 'Jake', 'Kara', 'Liam', 'Mia', 'Noah', 'Olivia', 'Penny', 'Quincy', 'Raymond', 'Sophie', 'Tommy', 'Uma'];
  const lastNames = ['Doe', 'Smith', 'Green', 'Johnson', 'Brown', 'Davis', 'White', 'Black', 'Wilson', 'Miller', 'Moore', 'Taylor', 'Thomas', 'Anderson', 'Clark', 'Lewis', 'Walker', 'Hall', 'Allen', 'Young', 'King', 'Scott', 'Adams', 'Nelson', 'Carter', 'Mitchell', 'Roberts', 'Baker', 'Turner', 'Phillips', 'Campbell', 'Parker', 'Edwards', 'Evans', 'Torres', 'Hall', 'Rivera', 'Ward', 'Morales', 'Ramirez', 'Hughes', 'Wood', 'Cox', 'Ward', 'Gray', 'West'];
  const jobs = ['Developer', 'Designer', 'Manager', 'Analyst', 'Consultant', 'Engineer', 'Architect', 'Salesperson', 'Writer', 'Director'];

  const getRandomAge = () => Math.floor(Math.random() * (65 - 18 + 1)) + 18;

  return Array.from({ length: count }, (_, index) => {
    const name = `${firstNames[index % firstNames.length]} ${lastNames[index % lastNames.length]}`;
    return {
      id: index + 1,
      name,
      age: getRandomAge(),
      tags: ['active', 'funny', 'sad', 'interesting'],
      job: jobs[Math.floor(Math.random() * jobs.length)],
    };
  });
};

// Demo data for the table
const data = generateDemoData(50);
const totalSize = data.length; // Define totalSize based on data length

// Table columns, including the action column
const columns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
  },
  {
    dataField: 'age',
    text: 'Age',
    sort: true,
  },
  {
    dataField: 'job',
    text: 'Job',
    formatter: (cell, row) => {
      return (
        <ColumnLink text={cell} handleClick={() => console.log("Action Click")} />
      );
    },
  },
  {
    Field: "tags",
    text: "Tags",
    formatter: (cell, row) => {
      return (
        <div className="d-flex align-items-center ">
          {row?.tags?.length > 0 ? (
            <>
              <div className="item-tag">
                {row?.tags[0]}
              </div>
              {row?.tags?.length > 1 && (
                <div className="item-tag ml-1" data-tip data-for={`tag-${row?.tags[0]}`}>
                  +{row?.tags?.length - 1}
                  <ReactTooltip id={`tag-${row?.tags[0]}`} place='top' effect='solid' className="tooltip-tags">
                    <div className='list-tags'>
                      {row?.tags?.map((tag, index) => (
                        index > 0 && tag && <span>{tag}</span>
                      ))}
                    </div>
                  </ReactTooltip>
                </div>
              )}
            </>
          ) : (<></>)}
        </div>
      );
    },
  },
  {
    dataField: "",
    text: "Action",
    headerClasses: "--text-primary font-weight-500 text-center",
    headerStyle: { width: "120px" },
    formatter: (cell, row) => (
      <div className="d-flex align-items-center justify-content-center action-more">
        <Icon src={More}/>
        <div className="action-more__list">
          <p>Share </p>
          <p>Edit</p>
          <p>Delete</p>
        </div>

        <div className="action-unshare ml-4">
          <p>
            Unshare
          </p>
        </div>
      </div>
    ),
  }
];

// Use the paginationOptions function to get the configuration
const paginationConfig = paginationOptions({ totalSize });

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
};

const TableCheckbox = () => {
  return (
    <PaginationProvider pagination={paginationFactory(paginationConfig)}>
      {({ paginationProps, paginationTableProps }) => (
        <div className="table-custom-common table-responsive mt-4">
          <BootstrapTable
            keyField="id"
            data={data.length ? data : []}
            columns={columns}
            bordered={false}
            selectRow={selectRow}
            condensed
            noDataIndication={() => (
              <MessageEmtyData
                title="No Results Found"
                text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
              />
            )}
            {...paginationTableProps}
          />
          <div className="pagination-custom-case d-flex justify-content-end mt-4">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  );
};

export default TableCheckbox;
