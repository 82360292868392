// src/utils/paginationConfig.js

/**
 * Configures pagination settings with customizable options.
 *
 * @param {Object} config - Configuration options for pagination.
 * @param {number} config.totalSize - Total number of items to paginate.
 * @param {React.Component} config.paginationComponent - Custom pagination component.
 * @param {boolean} config.custom - If true, enables custom pagination.
 * @param {number} config.pageSize - Number of pagination items to display.
 * @param {number} config.startPageIndex - Starting page index.
 * @param {Object} config.labels - Labels for pagination controls.
 * @param {boolean} config.showTotal - Display the total count of items.
 * @returns {Object} Pagination configuration object.
 */
const paginationOptions = ({
  totalSize = 0,
  paginationComponent = null,
  custom = true,
  pageSize = window.innerWidth <= 480 ? 1 : 4, // Adjust page size based on screen width
  startPageIndex = 1,
  labels = {}, // Start with an empty object
  showTotal = true
} = {}) => {
  
  // Default labels
  const defaultLabels = {
    first: "First",
    back: "Back",
    next: "Next",
    last: "Last",
    firstTitle: "First page",
    backTitle: "Previous page",
    nextTitle: "Next page",
    lastTitle: "Last page"
  };

  // Merge provided labels with defaults
  const mergedLabels = { ...defaultLabels, ...labels };

  return {
    paginationComponent, // Custom pagination component
    custom,
    paginationSize: pageSize,
    pageStartIndex: startPageIndex,
    firstPageText: mergedLabels.first,
    prePageText: mergedLabels.back,
    nextPageText: mergedLabels.next,
    lastPageText: mergedLabels.last,
    nextPageTitle: mergedLabels.nextTitle,
    prePageTitle: mergedLabels.backTitle,
    firstPageTitle: mergedLabels.firstTitle,
    lastPageTitle: mergedLabels.lastTitle,
    showTotal,
    totalSize
  };
};

export default paginationOptions;