import React from 'react';

function LoadingMessage(props) {
    const { isStreamDataLoading, contentStream, photoPatient, patientNameText } = props || {};
    return (
        <div className={`messengerCt__wrapper ${isStreamDataLoading ? "isShow" : ""}`}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={photoPatient}
                        alt=""
                    />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-username">
                            <div class="role-chat">{`${patientNameText || "Patient"}:`}</div>
                        </div>
                        <div className="messengerCt__message-text">
                            {contentStream && (
                                <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: contentStream }} />
                            )}
                            <div className="typing-loader ml-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingMessage;