import React, { useEffect } from 'react';
import { BtnIconDeleteV2, BtnIconEditV2 } from "@ui-partents/Button";
import { useSelector, useDispatch } from "react-redux";
import { getListSlideDesks, removeSlideDesk } from "@actions";
import { useHistory } from 'react-router-dom'
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { Icon } from '@components/common'
import { MessageEmtyData } from "@ui-partents/Message";
import File from '@images/teachV2/file-upload.svg';
import { LoaderText } from "@utils";

//moment
import moment from "moment";

// Import the pagination configuration
import {paginationOptions} from "@utils";

const TeachPresentationLibrary = () => {
  const { SearchBar } = Search;
  let history = useHistory();
  const dispatch = useDispatch();
  const listCase = useSelector((state) => state?.getListSlideDesks || []);
  
  const handleEditButtonClick = (id) => {
    history.push({
      pathname: `/teach/presentation/${id}`,
      state: {  // location state
        listCase: true, 
      },
    })
  };

  useEffect(() => {
    dispatch(getListSlideDesks({
      action_type: "list_slide_decks"
    }));
  }, [dispatch])

  const columns = [
    {
      dataField: "title",
      text: "Title",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <Icon src={File}/>
            <p className='ml-1'>{cell}</p>
          </div>
        );
      },
    },
    {
      dataField: "updated_at",
      text: "Date Created",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <div className='format-time text-nowrap'>{moment(cell).format('DD/MM/YYYY hh:mm A')}</div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "--text-primary font-weight-500 text-center",
      headerStyle: { width: "72px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-start">
            <BtnIconEditV2
              handleEditButtonClick={() => handleEditButtonClick(row.id)}
              colorIcon={`#16B364`}
            />
            <WrapModalDeleteCommon
              title={`Delete Presentation`}
              sub={`Are you sure you want to delete this presentation? This action cannot be undone.`}
              handleRemove={handleRemove}
              idRemove={row?.id}
            >
              {({onOpen}) => (
                <BtnIconDeleteV2 handleDelete={onOpen} />
              )}
            </WrapModalDeleteCommon>
          </div>
        );
      },
    }
  ];

  const handleRemove = (idDelete) => {
    const params = {
      action_type: "delete_slide_deck",
      id: idDelete,
    };
    dispatch(removeSlideDesk(params));
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="id"
        data={listCase?.data || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-library__filter d-flex align-items-center justify-content-between">
              <p>Total Teaching Slides: {listCase?.data?.length}</p>
              <div className="boxSearch">
                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                  paginationProps.page = 1
                  toolkitprops.searchProps.onSearch(e)
                }}/>
              </div>
            </div>
            {console.log(toolkitprops)}
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={(listCase?.data && listCase?.data) || []}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = listCase?.data?.length || 0; // Dynamically determine the total size

  // Generate pagination configuration dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className="table-library position-relative">
      {listCase?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
      ) : (
        listCase?.data?.length === 0 ? (
          <MessageEmtyData
            title="Your Library is Empty"
            text="It looks like you haven't generated any teaching slides yet. Start creating a new one to see it here."
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default TeachPresentationLibrary
