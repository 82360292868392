import React, { useContext } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import imgLogo from '@images/common/med2lab_logo.png';

//redux
import { useSelector } from "react-redux";

//Themes
import ThemeContext from "../../../context/ThemeContext";

//components
import { ButtonMenuMobile, ButtonBackAssigment } from "../Header";

//Common
import { SwitchRoleMobile } from "@ui-partents/SwitchRole";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

const HeaderLeft = ({ isLearner }) => {
    // Themes
    const theme = useContext(ThemeContext);
    const primaryLogo = theme?.PrimaryLogo;
    const { id } = useParams();
    const location = useLocation();

    // Use screen size context
    const { isMobile } = useScreenSize(); //screenWidth

    //redux
    const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data || {});
    const is_instructor = AccountInfoData?.Account?.is_instructor === "Yes";
    return (
        <div className="header_left">
            {/* Chỉ hiển thị ở learn/case và pc */}
            {isLearner && !isMobile && (
                <div className={`logo d-flex`}>
                    <Link to={"/"}>
                        <img style={{ height: '60px' }} src={primaryLogo || imgLogo} alt="Med2lab Logo" className="img-fluid" />
                    </Link>
                </div>
            )}
            {isMobile &&
                <div className="d-flex align-items-center">
                    {location?.pathname?.includes('/learn/assignments') && id ? (
                        <ButtonBackAssigment />
                    ) : (
                        <ButtonMenuMobile />
                    )}
                    {is_instructor && !isLearner && (
                        <div className="ml-auto">
                            <SwitchRoleMobile />
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default HeaderLeft;