import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextAreaCommon,
  UploadCommon,
  ChoiceCommon,
  ReviewCommon,
  SelectListCommon,
  SelectListCommonImage,
} from "@components/teachV2/performanceReport/listReportCommon";
import { loadWizard } from "@actions";
import { useSelector, useDispatch } from "react-redux";
import { ModalRegenerate } from "@components/teachV2/performanceReport";
import { wizardConstants } from "@constants";
import { ProgressStep } from "@ui-partents/ProgressStep";
import LoadingGenerate from "@components/teachV2/CommonComponent/LoadingGenerate";
import { ResultMcqWizard } from "@components/teachV2/performanceReport/resultMcqWizard";

const PerformanceReport = () => {
  const dispatch = useDispatch()
  const dataWizard = useSelector((state) => state.loadWizard || {});
  const [step, setStep] = useState(1);
  const [contentLast, setContentLast] = useState();
  const [loadingResult, setLoadingResult] = useState(false)
  const [modal, setModal] = useState({
    ModalRegenerate: false
  })
  const [dataContentFile, setDataContentFile] = useState()
  
  const toggle = (type) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  }

  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  useEffect(() => {
    dispatch(loadWizard(1));
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_REFERENCE_SUCCESS, payload: null });
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_MEDIA_SUCCESS, payload: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { 
    register, 
    handleSubmit, 
    setValue, 
    watch, 
    control,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    let defaultValues = {};
    dataWizard?.data?.Params?.forEach((params) => {
      const { name, default_value } = params;
      defaultValues[name] = default_value
    })
    reset({ ...defaultValues });
  }, [reset, dataWizard])

  const onSubmit = (data) => console.log(data);

  const selectTypeFlow = (contents, parent) => {
    switch (contents?.view) {
      case "simple_form":
        return (
          <TextAreaCommon
            step={step}
            register={register}
            handleNext={handleNext}
            handleBack={handleBack}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            parent={parent}
            selectTypeFlow={selectTypeFlow}
            control={control}
          />
        )
      case "select_card":
        return (
          <ChoiceCommon
            step={step}
            register={register}
            handleNext={handleNext}
            handleBack={handleBack}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            parent={parent}
            getValues={getValues}
            selectTypeFlow={selectTypeFlow}
          />
        )
      case "select_from_list":
        return (
          contents?.data_source === "media_library" ? (
            <SelectListCommonImage
              step={step}
              register={register}
              handleNext={handleNext}
              handleBack={handleBack}
              watch={watch}
              setValue={setValue}
              title={contents?.title}
              subtitle={contents?.subtitle}
              dataForm={contents}
              parent={parent}
              getValues={getValues}
              selectTypeFlow={selectTypeFlow}
            />
          ) : (
            <SelectListCommon
              step={step}
              register={register}
              handleNext={handleNext}
              handleBack={handleBack}
              watch={watch}
              setValue={setValue}
              title={contents?.title}
              subtitle={contents?.subtitle}
              dataForm={contents}
              parent={parent}
              getValues={getValues}
              selectTypeFlow={selectTypeFlow}
            />
          )
        )  
      case "upload_file":
        return (
          <UploadCommon
            step={step}
            register={register}
            handleNext={handleNext}
            handleBack={handleBack}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            parent={parent}
            getValues={getValues}
            selectTypeFlow={selectTypeFlow}
          />
        )
      case "review":
        return (
          <ReviewCommon
            step={step}
            register={register}
            handleNext={handleNext}
            handleBack={handleBack}
            watch={watch}
            setValue={setValue}
            title={contents?.title}
            subtitle={contents?.subtitle}
            dataForm={contents}
            parent={parent}
            getValues={getValues}
            selectTypeFlow={selectTypeFlow}
            setLoadingResult={setLoadingResult}
            loadingResult={loadingResult}
            setDataContentFile={setDataContentFile}
            dataContentFile={dataContentFile}
            setContentLast={setContentLast}
          />
        )
      default:
        break;
    }
  }

  const handleNewRegenerate = () => {
    dispatch(loadWizard(1));
    setDataContentFile()
    setStep(1)
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_REFERENCE_SUCCESS, payload: null });
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_MEDIA_SUCCESS, payload: null });
  }

  return (
    <div className="wrapper -performancePage">
      {loadingResult ? (
        <>
          <ProgressStep listStep={dataWizard?.data?.Flow} step={step}/>
          <LoadingGenerate 
            title="Generating Your Content"
            subtitle={`Just a moment... Our AI is preparing your tailored content.`}
          />
        </>
      ) : (
        !dataContentFile ? (
          <>
            <ProgressStep listStep={dataWizard?.data?.Flow} step={step}/>
            <form className="form-report" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-report__question">
                {dataWizard?.data?.Flow?.map((flow, index) => (
                  (index + 1) === step && selectTypeFlow(flow?.Contents, flow)
                ))}
              </div>
            </form>
          </>
        ) : (
          <ResultMcqWizard 
            watch={watch}
            dataContentFile={dataContentFile}
            loadingResult={loadingResult}
            toggle={toggle}
          />
        )
      )}
      {modal?.ModalRegenerate && (
        <ModalRegenerate 
          isOpen={modal?.ModalRegenerate}
          toggle={() => toggle('ModalRegenerate')}
          setValue={setValue}
          watch={watch}
          item={dataWizard?.data?.Flow}
          handleNewRegenerate={handleNewRegenerate}
          setLoadingResult={setLoadingResult}
          setDataContentFile={setDataContentFile}
          dataContentFile={dataContentFile}
          getValues={getValues}
          dataForm={contentLast}
        />
      )}
    </div>
  );
};

export default PerformanceReport;
