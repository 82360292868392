import React, { Suspense, useEffect, useState, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actFetchTeachCaseRequest } from "@actions";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Effect
import WOW from "wowjs";

//lodash
import _ from "lodash";

//Theme
import "../../theme/style.css";
import ThemeContext from "../../context/ThemeContext";

// Components
import { SidebarMain, SidebarMakansafe } from "@components/layouts/Sidebar";
import { MainContentIner } from "@components/layouts"; //CaseAccessMessage
import { HeaderTop, MenuMobileFullScreen } from "@components/layouts/Header";
import { HeaderTopMakansafe } from "@components/layouts/HeaderMakansafe";
import { LoaderPage } from "@utils";
import { Helmet } from "react-helmet";

const MasterLayout = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isLayout, isLearner, classPage, children, makansafeLayout, isBgWhite } = props;

  const pathName = location.pathname;
  const userData = useSelector((state) => state.authUser || {});
  const themeData = useSelector((state) => state.theme || {});
  const themeGlobal = themeData?.data;

  const [stateAccount, setAccount] = useState("");
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(makansafeLayout ? true : window.innerWidth < 991);

  const isViewTeach = pathName.includes("teach");

  useEffect(() => {
    new WOW.WOW({ live: false }).init();
  }, []);

  // Check resize Sidebar makansafe
  useEffect(() => {
    setIsSidebarCollapsed(makansafeLayout ? true : window.innerWidth < 991);
  }, [makansafeLayout]);

  // Check Style for Theme
  useEffect(() => {
    const PrimaryColor = themeData?.data?.PrimaryColor || "#0089C3";
    document.documentElement.style.setProperty("--main-color", PrimaryColor);
  }, [themeData]);

  // Local Storage for Account
  useEffect(() => {
    const Account = JSON.parse(localStorage.getItem("Account"));
    setAccount(Account || userData.account);
  }, [userData]);

  // Check style for Login View
  useEffect(() => {
    const shouldRemoveLoginClass = pathName.includes("weitzman/") || pathName.includes("makansafe/");

    if (shouldRemoveLoginClass) {
      document.body.classList.remove("-is-login");
    } else {
      const bodyClassMap = {
        "-is-login-style2": pathName.includes("/ucr") || pathName.includes("/nus") || pathName.includes("/app-chat"),
        "-bg-kachi-health": pathName.includes("/kachi-health"),
      };

      Object.keys(bodyClassMap).forEach((className) => {
        document.body.classList.toggle(className, bodyClassMap[className]);
      });
    }
  }, [pathName]);

  // Check Token call API
  useEffect(() => {
    const isToken = localStorage.getItem("token");
    if (isToken && id && isViewTeach) {
      dispatch(actFetchTeachCaseRequest(id));
    }
  }, [isViewTeach, dispatch, id]);

  //Redux
  const caseDetails = useSelector((state) => state?.teachCase?.data || null);
  const caseLearner = useSelector((state) => state.cases || {});
  const paramsCommon = useMemo(() => _.merge(stateAccount, { caseDetails }), [stateAccount, caseDetails]);

  //Menu Mobile
  const isOpenMenuMobile = useSelector((state) => state.isOpenMenuMobile.isOpen);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  // Render Header
  const renderHeader = () => {
    const Header = makansafeLayout ? HeaderTopMakansafe : HeaderTop;
    return (
      <Header
        {...props}
        onClickSideBar={toggleSidebar}
        isSidebarCollapsed={isSidebarCollapsed}
        profileInfo={stateAccount}
        caseLearner={caseLearner}
        makansafeLayout={makansafeLayout}
      />
    );
  };

  // Render Sidebar
  const renderSidebar = () => {
    const Sidebar = makansafeLayout ? SidebarMakansafe : SidebarMain;
    return (
      <Sidebar
        {...props}
        isSidebarCollapsed={isSidebarCollapsed}
        profileInfo={stateAccount}
      />
    );
  };

  //get data store
  const caseData = useSelector((state) => state.cases || []);
  const isLockCase = caseData?.error === "not_authorized";
  return (
    <div className={classPage}>
      <Helmet>
        <title>Med2Lab | AI Powered Healthcare Training & Engagement</title>
      </Helmet>
      <ThemeContext.Provider value={themeGlobal}>
        <Suspense fallback={<LoaderPage />}>
          <ToastContainer
            position="top-center"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            transition={Slide}
            limit={1}
          />
          {isLayout || makansafeLayout ? (
            <>
              {/* Nếu là view learner thì không có sidebar */}
              {!isLearner && renderSidebar()}
              <div className={`${isLearner ? 'interface-leaner-fullWidth' : 'learner-interface-hasSidebar'}`}>
                <main className={`main_content ${isSidebarCollapsed ? 'collapsed' : ''} ${isBgWhite && "is-white"}`}>
                  {renderHeader()}
                  <MainContentIner {...props} isLearner={isLearner} isLockCase={isLockCase}>
                    {React.Children.map(children, (child) => React.cloneElement(child, paramsCommon))}
                  </MainContentIner>
                </main>
              </div>
            </>
          ) : (
            <main>
              {React.Children.map(children, (child) => React.cloneElement(child, paramsCommon))}
            </main>
          )}
        </Suspense>
        <MenuMobileFullScreen {...props} isOpen={isOpenMenuMobile} />
      </ThemeContext.Provider>
    </div>
  );
};

export default MasterLayout;