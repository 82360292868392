import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { Icon } from '@components/common'
import FileCase from '@images/teachV2/file-case.svg'
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector, useDispatch } from "react-redux";
import { getListCaseScript } from "@actions";
import { apiCaller } from "@utils";
import Error from '@images/login/error.svg'
import Success from '@images/login/success.svg'
import { useHistory } from "react-router-dom";
import McqWaiting from '@images/teachV2/waiting-mcq.gif'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { MessageEmtyData } from "@ui-partents/Message";

// Import the pagination configuration
import {paginationOptions} from "@utils";

const ModalCaseScenario = (props) => {
  const { isOpen, toggle } = props;
  let history = useHistory();
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const listCase = useSelector((state) => state?.getListCaseScript || []);
  const [dataActive, setDataActive] = useState()
  const [dataResult, setDataResult] = useState();
  const [loadingResult, setLoadingResult] = useState();
  //---------stream Data-------//
  const [contentStream, setContentStream] = useState();
  let intervalIDRef = useRef(null);

  useEffect(() => {
    dispatch(getListCaseScript({
      action_type: "list_case_scripts"
    }));
  }, [dispatch])

  const columns = [
    {
      dataField: "title",
      text: "",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      sort: true,
      editable: false,
      formatter: (value, row) => {
        return (
          <div
            className={`d-flex item-book`} 
          >
            <Icon src={FileCase}/>
            <p className="ml-2">{value}</p>
          </div>
        );
      },
    },
  ]

  const selectRow = {
    mode: 'radio',
    hideSelectColumn: true, // single row selection
    clickToSelect: true,
    classes: 'active-item',
    onSelect: (row, isSelect, rowIndex, e) => {
      setDataActive(row)
    }
  };

  const handleImportVoc = (file) => {
    setLoadingResult(true)
    const params = {
      "action_type": "interactive_module_creation_pipeline",
      "gpt_model": "gpt-4o",
      "case_script_id": dataActive.id
    }
    const filePromise = apiCaller(`/api/medicalcase/functional/`, "POST", params)
      .then(response => {
        if (response.status === 200) {
          setDataResult(response.data)
          setTimeout(() => {
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }, 1000)
        }
        setLoadingResult(false)
      })
      .catch(error => {
        console.error("Error in API call", error);
        setLoadingResult(false)
      });
    handleStreamData(filePromise)
  };

  // stream Data
  const handleStreamData = (questionPromise) => {
    Promise.race([
      questionPromise,
      new Promise((resolve) => setTimeout(resolve, 2000, "timeout")),
    ]).then((result) => {
      if (result !== "timeout") {
        console.log("API question returned before timeout");
        return;
      }

      console.log("API question timed out. Calling API stream_data...");
      intervalIDRef.current = setInterval(() => {
        apiCaller(`/api/account/stream/`, "GET").then((res) => {
          const dataStream = res?.data;
          if (res?.status === 200) {
            setContentStream(dataStream);
          } else {
            setContentStream({});
            // Xoa Stream
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }
        }).catch((err) => {
          console.log(err);
        });
      }, 2000);
    });
  };

  const handleError = () => {
    setDataResult(null)
    setContentStream(null)
    setDataActive(null)
  }

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="id"
          data={listCase.data || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="table-bank__filter d-flex align-items-center justify-content-between">
                <div className="boxSearch">
                  <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                    paginationProps.page = 1
                    toolkitprops.searchProps.onSearch(e)
                  }}/>
                </div>
              </div>
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive list-books"
                data={listCase.data || []}
                columns={columns}
                noDataIndication={() => (
                  <MessageEmtyData 
                    title="Your List is Empty"
                    text="It looks like you haven't uploaded any reference documents yet. Click ‘Go to Reference Library’ and upload your document to add new reference materials."
                  />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                selectRow={selectRow}
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="pagination-custom-case d-flex justify-content-end ml-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  // Use the function to get your pagination configuration
  const paginationConfig = paginationOptions({ totalSize: listCase?.data?.length || 0 });
  return (
    <Modal centered={true} isOpen={isOpen} toggle={toggle} backdrop={`static`} style={{maxWidth: (contentStream || dataResult) ? '605px' : '830px'}} className='modal-scenario'>
      {(contentStream || dataResult) ? (
        <ModalHeader></ModalHeader>
      ) : (
        <ModalHeader toggle={toggle}></ModalHeader>
      )}
      <ModalBody className="p-3">
        {(contentStream || dataResult) ? (
          !dataResult ? (
            <div className="waiting-generate">
              {/* <Icon src={FileWaitGenerate} width={192} height={192}/> */}
              <img src={McqWaiting} alt="mcq" />
              <div className="waiting-generate__content">
                <h1>Generating Interactive Module</h1>
                <p>{contentStream?.status ? contentStream?.status : "We are setting up your new interactive module! It might take a few minutes..."}</p>
              </div>
            </div>
          ) : (
            <div className='waiting-generate'>
              {dataResult?.status === "success" ? (
                <>
                  <Icon src={Success}/>
                  <div className="waiting-generate__content">
                    <h1>Your Interactive Module Has Been Created!</h1>
                    <p>Click "Run Module" to see it in action or "Edit Module" to <br /> make any necessary edits.<br /> You can find this module in your Module Library.</p>
                  </div>
                  <div className="waiting-generate__action">
                    <Button
                      onClick={() => history.push(`/teach/module/storyboard/${dataResult?.case_id}`)}
                    >
                      Edit Module
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => window.open(`/learn/case/${dataResult?.case_id}`, "_blank")}
                    >
                      Run Module
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Icon src={Error}/>
                  <div className="waiting-generate__content">
                    <h1>Incomplete Case Scenario</h1>
                    <p>Some required elements are missing for interactive module creation. Please complete all required sections to proceed.</p>
                  </div>
                  <div className="waiting-generate__action">
                    <Button
                      onClick={handleError}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => history.push(`/teach/script/${dataActive.id}`)}
                    >
                      Update Scenario
                    </Button>
                  </div>
                </>
              )}
            </div>
          )
        ) : (
          listCase?.isLoading ? (
            <div className="w-100 d-flex justify-content-center">
              <ClipLoader color="#0089c3" size={26}/>
            </div>
          ) : (
            <>
              <div className="title-scenario">
                <h1>Select an Existing Scenario from Your Library</h1>
              </div>
              <PaginationProvider pagination={paginationFactory(paginationConfig)}>
                {contentTable}
              </PaginationProvider>
            </>
          )
        )}
      </ModalBody>
      {(contentStream || dataResult) ? (
        <></>
      ) : (
        <ModalFooter>
          <Button 
            className="btn btn-secondary" 
            onClick={toggle}
            disabled={loadingResult}
          >
            Cancel
          </Button>
          <Button 
            className="btn d-flex align-items-center" 
            color="primary"
            onClick={handleImportVoc}
            disabled={!dataActive || loadingResult}
          >
            Confirm
            {loadingResult && <ClipLoader color="#FFF" size={16}/>}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ModalCaseScenario