import React from 'react';

function LabSearch(props) {
    const { onSearch, searchText } = props && props;
    return (
        <div className="boxSearch justify-content-end">
            <input
                type="text"
                className="form-control"
                onChange={onSearch}
                value={searchText}
                placeholder="Search"
                autoComplete="off"
            />
            <button className="--bg-primary btnSearch btn btn-primary">
                <i className="fa fa-search" aria-hidden="true" />
            </button>
        </div>
    );
};

export default LabSearch;