import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import ImageDoctor from '@images/learnerv2/doctor.png';
import { useHistory } from "react-router-dom";
import { CaseTitle } from "../../InteractAssistant";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

//components
import { CurrentTimepointName } from "../AssistantQuestion";

function InteractAssistantBegin(props) {
    const { handleBeginLearner, isLoadingGotIt, typeQuestion, content, caseData } = props;
    const caseTitle = caseData?.data?.Case?.Title;
    const CurrentTimePointDisplayName = caseData?.data?.Case?.Timeline?.CurrentTimePointDisplayName || '';
    let history = useHistory();

    // Use screen size context
    const { isMobile } = useScreenSize();

    // State for dynamic height
    const [isComponentVisible, setIsComponentVisible] = useState(true);

    useEffect(() => {
        new WOW.WOW({
            live: false,
        }).init();
    }, []);

    // Handle redirection
    const handleRedirectUrl = () => {
        history.push(`/learn/assignments`);
    }

    useEffect(() => {
        if (isComponentVisible) {
            document.body.style.overflow = 'hidden'; // Lock scrolling
        } else {
            document.body.style.overflow = ''; // Unlock scrolling
        }

        // Clean up on component unmount
        return () => {
            document.body.style.overflow = '';
        };
    }, [isComponentVisible]);

    console.log(CurrentTimePointDisplayName, "CurrentTimePointDisplayName....");
    return (
        <div id="begin-case-view">
            <div className={`screen-learn-start ${isMobile ? "is-mobile" : ""}`}>
                <div
                    className={`screen-learn-start__left ${typeQuestion === 'begin_question' && "wow zoomIn animated"}`}
                    style={{ backgroundImage: `url(${ImageDoctor})` }}
                ></div>
                <div className="screen-learn-start__right -show-icon-top wow fadeInUp animated">
                    <div className="medical-info">
                        <div className="medical-info-inner">
                            <div className="medical-info-top">
                                <CaseTitle {...props} title={caseTitle} />
                                {isMobile && <CurrentTimepointName name={CurrentTimePointDisplayName} /> }
                                <div className="medical-description scrollbarStyle">
                                    <div className="medical-description__content" dangerouslySetInnerHTML={{ __html: content }} />
                                </div>
                            </div>
                            <div className="button-group-footer d-flex justify-content-end">
                                <button
                                    className="button-default mr-2"
                                    onClick={handleRedirectUrl}
                                    disabled={isLoadingGotIt}
                                >
                                    Back to Assignments
                                </button>
                                <button
                                    className="button-yellow"
                                    onClick={() => {
                                        handleBeginLearner();
                                        setIsComponentVisible(false); // Hide component
                                    }}
                                    disabled={isLoadingGotIt}
                                >
                                    {isLoadingGotIt && (
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                    )}
                                    Start the Encounter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InteractAssistantBegin;